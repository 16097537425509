import React from 'react';
import { Tooltip } from 'components/common/Tooltip';
import { StyledButton } from './styled';

interface Props {
  isActive?: boolean;
  onClick: () => void;
  tooltip?: string;
  children: JSX.Element | string;
  disabled?: boolean;
  color?: 'primary' | 'secondary' | 'error' | 'tertiary' | 'transparent';
  condensed?: boolean;
}

export const MenuButton = ({
  isActive,
  onClick,
  tooltip,
  children,
  disabled,
  color,
}: Props) => (
  <Tooltip title={tooltip}>
    <StyledButton
      variant="icon"
      color={color || 'primary'}
      onClick={onClick}
      disabled={disabled}
      active={isActive}
    >
      {children}
    </StyledButton>
  </Tooltip>
);
