import { CompanyRevenueType } from "interfaces/company";
import {
  CohortDimensionOptionsContracts,
  CohortDimensionOptionsSubscriptions,
} from "./cohortDetail";
import { DetailTableOptions } from "interfaces/revenueDetail";

export const RevenueDetailMetricsOptions: DetailTableOptions = [
  { name: 'Revenue', value: 'revenue' },
  { name: 'New', value: 'new' },
  { name: 'Upgrade', value: 'upgrade' },
  { name: 'New + Upgrade', value: 'newUpgrade' },
  { name: 'Downgrade', value: 'downgrade' },
  { name: 'Net Expansion', value: 'netExpansion' },
  { name: 'Cancelled', value: 'cancelled' },
  { name: 'Net New', value: 'netNew' },
  { name: 'Reactivated', value: 'reactivated' },
  { name: 'Deactivated', value: 'deactivated' },
  { name: 'New Excl Reactivated', value: 'newExclReactivated' },
  { name: 'Cancelled Excl Deactivated', value: 'cancelledExclDeactivated' },
];

export const RevenueDetailDimensionOptionsContracts = [
  { name: 'Customer' },
  { name: 'Customer Tags' },
  { name: 'Product' },
  { name: 'Product Tags' },
  { name: 'Contract' },
  { name: 'Contract Tags' },
  { name: 'Contract Line Tags' },
  { name: 'None' },
];

export const RevenueDetailDimensionOptionsSubscriptions = [
  { name: 'Customer' },
  { name: 'Customer Tags' },
  { name: 'Product' },
  { name: 'Product Tags' },
  { name: 'Subscription Tags' },
  { name: 'None' },
];

export const getDimensionOptions = (isCohorts: boolean, companyRevenueType: CompanyRevenueType) => {
  if (isCohorts) {
    if (companyRevenueType === CompanyRevenueType.CONTRACTS) {
      return CohortDimensionOptionsContracts;
    } else {
      return CohortDimensionOptionsSubscriptions;
    }
  } else {
    if (companyRevenueType === CompanyRevenueType.CONTRACTS) {
      return RevenueDetailDimensionOptionsContracts;
    } else {
      return RevenueDetailDimensionOptionsSubscriptions;
    }
  }
};