import styled from "styled-components";
import { theme } from "theme/theme";
import { ANIMATION_DURATION } from "utils/constants";

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;

  button {
    border-radius: 0;
    border-right-width: 0;
    min-width: 32px;
    height: 32px;

    &:hover {
      box-shadow: none;
    }
  }

  button:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  button:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right-width: 1px;
  }
`;

export const DatepickerWrapper = styled.div`
  width: 110px;

  & > div {
    padding: 0;

    & > div {
      line-height: 1;
      font-size: 13px;
      font-weight: 600;
      border-radius: 0;
      margin: 0;
      padding: 0;
      border: 1px solid ${theme.colors.primary};
      margin-right: -1px;

      svg {
        display: none;
      }

      input {
        padding: 6px 12px;
        height: 30px;
        background-color: ${theme.colors.white};;
        color: ${theme.colors.primary};
        text-align: center;
        cursor: pointer;
        transition: all ${ANIMATION_DURATION}ms ease;

        &:hover {
          background-color: ${theme.colors.primaryBleach};
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: none;
        border-radius: 0;
        pointer-events: none;
        transition: all 200ms ease;
      }

      :focus,
      :focus-visible,
      :active,
      :hover,
      :focus-within {
        border: 1px solid ${theme.colors.primary};

        &::before {
          border: unset;
        }
      }
    }
  }
`;

