import { FactaChart } from 'components/FactaChart';
import { Box } from 'components/common/Box';
import { useAppDispatch } from 'hooks/redux';
import { ChartConfig } from 'interfaces/charts';
import { invalidateTags } from 'store/services/api';
import { MetricsTags } from 'store/services/api-tags';
import {
  useGetMetricChartQuery,
  useUpdateMetricChartMutation,
} from 'store/services/metrics';
import { Dropdown } from 'components/common/Dropdown';

interface Period {
  name: string;
  value: 'monthly' | 'quarterly' | 'annual';
}

interface Props {
  metricId: string;
}

export const MetricChart = ({ metricId }: Props) => {
  const dispatch = useAppDispatch();
  const { data: metricChartData, isLoading } = useGetMetricChartQuery({ id: metricId });
  const [updateChart] = useUpdateMetricChartMutation();

  const periods: Period[] = [
    { name: 'Monthly', value: 'monthly' },
    { name: 'Quarterly', value: 'quarterly' },
    { name: 'Annual', value: 'annual' },
  ];

  const handleConfigChange = (config: ChartConfig) => {
    updateChart({
      id: metricId,
      ...config,
    });
  };

  const handlePeriodTypeChange = (periodType: "monthly" | "quarterly" | "annual") => {
    updateChart({
      id: metricId,
      ...metricChartData!.chartConfig,
      chartStartPeriod: undefined,
      chartEndPeriod: undefined,
      chartPeriodType: periodType,
    })
      .unwrap()
      .then(() => {
        dispatch(invalidateTags([MetricsTags.Chart]));
      });
  };

  return (
    <Box>
      <FactaChart
        loading={isLoading}
        chartData={metricChartData}
        tooltipStyle="full"
        onChartConfigChange={(newConfig) => handleConfigChange(newConfig)}
        noDataMessage="Try using Sync data button in the header."
        additionalFormattingBoxes={metricChartData ? [
          {
            key: 'period-selector',
            title: 'Period',
            componentRenderer: () => (
              <Dropdown
                values={metricChartData?.chartConfig.chartPeriodType ? [periods.find(({ value }) => value === metricChartData.chartConfig.chartPeriodType)] : []}
                options={periods || []}
                labelField="name"
                valueField="value"
                searchBy="name"
                onChange={(val) => handlePeriodTypeChange(val[0].value)}
                condensed
                minWidth={100}
                searchable={false}
              />
            ),
          },
        ] : []}
      />
    </Box>
  );
};
