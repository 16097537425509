import React, { useEffect } from "react";

export const useClickOutside = (ref: React.RefObject<any>, func: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const dropdownElement = document.getElementById('datepicker-portal');
      if (ref.current && !ref.current.contains(event.target) && !dropdownElement?.contains(event.target as Node)) {
        func();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [func, ref]);
};
