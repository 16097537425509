import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  LeftPane,
  RightPane,
} from 'components/Layout';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import { Button } from 'components/common/Button';
import {
  RefreshIcon,
  DownloadIcon,
  RotatingRefreshIcon,
} from 'assets/icons';
import { AgGridReact } from '@ag-grid-community/react';
import { GridOptions } from '@ag-grid-community/core';
import { useRightPanes } from 'hooks/useRightPanes';
import { Tag } from 'interfaces/tags';
import { getColumnDefs } from './columnDefinitions';
import { useGetAllTagsQuery } from 'store/services/tags';
import { AddTag } from './AddTag';
import { DeleteTags } from './DeleteTags';
import { EditTags } from './EditTags';
import {
  highlightNodeById,
  reselectNodeById,
} from 'utils/aggrid';
import { Tooltip } from 'components/common/Tooltip';
import { FactaTable } from 'components/FactaTable';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import { CompanyRevenueType } from 'interfaces/company';
import { getManagementInfo } from 'utils/managementInfo';

interface Props {
  setCustomMarkerData: (customData: {}) => void;
  companyRevenueType?: CompanyRevenueType;
}

export const Tags = ({ setCustomMarkerData, companyRevenueType }: Props) => {
  const gridRef = useRef<AgGridReact>(null);
  const columnDefs = getColumnDefs(companyRevenueType);
  const [selectedRows, setSelectedRows] = useState<Tag[]>([]);
  const { rightPanes, setSelectedPane, resetPanes } = useRightPanes({
    add: false,
    edit: true,
    delete: false,
  });

  const managementInfo = getManagementInfo(companyRevenueType);

  const gridOptions: GridOptions = {
    onSelectionChanged: (e) => setSelectedRows(e.api.getSelectedRows()),
  };

  const handleAddTag = () => {
    setSelectedPane('add');
  };

  const handleTriggerDelete = () => {
    setSelectedPane('delete');
  };

  const handleCSVDownload = () => gridRef.current?.api.exportDataAsCsv({
    fileName: 'insights-tags.csv',
    skipRowGroups: true,
    columnKeys: [
      'name',
      'description',
      'type',
    ],
  });

  const {
    data,
    isLoading,
    isFetching,
    refetch,
  } = useGetAllTagsQuery();

  const tags = data?.filter(({ deleted }) => !deleted);

  useEffect(() => {
    setCustomMarkerData({
      selectedIds: selectedRows.map((row) => row.id),
    });
  }, [selectedRows, setCustomMarkerData]);

  return (
    <>
      <LeftPane>
        <ButtonsContainer>
          <Breadcrumbs>
            <Breadcrumb link="/data-input">Data Sources</Breadcrumb>
            <Breadcrumb link={managementInfo.path}>{managementInfo.name}</Breadcrumb>
            <Breadcrumb>Tags</Breadcrumb>
          </Breadcrumbs>
          <Spacer />
          <Tooltip title="Refresh table data">
            <Button
              variant="icon"
              aria-label="Refresh"
              onClick={refetch}
            >
              {isFetching ? <RotatingRefreshIcon /> : <RefreshIcon />}
            </Button>
          </Tooltip>
          <Tooltip title="Download CSV">
            <Button
              variant="icon"
              aria-label="Download"
              onClick={handleCSVDownload}
            >
              <DownloadIcon />
            </Button>
          </Tooltip>
        </ButtonsContainer>
        <SectionLabel>
          <span>
            Tags
          </span>
        </SectionLabel>
        <FactaTable
          gridRef={gridRef}
          data={tags}
          useNativeFilters
          columnDefs={columnDefs}
          isLoading={isLoading || isFetching}
          isDisabled={rightPanes.add}
          onClickAdd={handleAddTag}
          selectedRowsLength={selectedRows.length}
          entityName="Tag"
          gridOptions={gridOptions}
          showQuickSearch
        />
      </LeftPane>
      <RightPane isOpen={rightPanes.add}>
        <AddTag
          companyRevenueType={companyRevenueType}
          onClose={() => resetPanes()}
          onSuccess={(result) => highlightNodeById(result.id, gridRef)}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.edit && selectedRows.length > 0}>
        <EditTags
          companyRevenueType={companyRevenueType}
          selectedRows={selectedRows}
          onSuccess={(id) => highlightNodeById(id, gridRef)}
          onSave={(id) => reselectNodeById(id, gridRef)}
          onDelete={handleTriggerDelete}
          onClose={() => {
            resetPanes();
            gridRef.current?.api.deselectAll();
          }}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.delete && selectedRows.length > 0}>
        <DeleteTags
          selectedRows={selectedRows}
          onClose={() => {
            resetPanes();
            gridRef.current?.api.deselectAll();
          }}
        />
      </RightPane>
    </>
  );
};
