import { api } from "./api";
import { User } from "interfaces/auth";
import { AuthTags } from "./api-tags";
import { authActions } from "store/slices/auth";

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAuthMe: builder.query<User, void>({
      query: () => ({
        url: `auth/me`,
        omitCompanyId: true,
      }),
      transformResponse: (user: User) => {
        return {
          ...user,
          companies: user.companies.sort((comp1, comp2) => comp1.name.localeCompare(comp2.name)),
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: user } = await queryFulfilled;
          dispatch(authActions.setCompanyId(user.currentCompanyId));
        } catch {}
      },
      providesTags: [AuthTags.User],
    }),
    putAuthMe: builder.mutation<User, Pick<User, 'firstName' | 'lastName'>>({
      query: (body) => ({
        method: 'PUT',
        url: `auth/me`,
        omitCompanyId: true,
        body,
      }),
      transformResponse: (user: User) => {
        return {
          ...user,
          companies: user.companies.sort((comp1, comp2) => comp1.name.localeCompare(comp2.name)),
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: updatedMe} = await queryFulfilled;
          dispatch(authApi.util
            .updateQueryData('getAuthMe', undefined, () => updatedMe));
        } catch {}
      },
    }),
    changeCompany: builder.mutation<void, { companyId: string }>({
      query: (companyId) => ({
        url: 'auth/change-company',
        omitCompanyId: true,
        method: 'POST',
        body: companyId,
      }),
      invalidatesTags: (res, err) => !err ? [AuthTags.User] : [],
    }),
  }),
});

export const {
  useGetAuthMeQuery,
  useChangeCompanyMutation,
  usePutAuthMeMutation,
} = authApi;
