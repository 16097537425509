import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react';
import {
  AgGridReact,
  AgGridReactProps,
} from '@ag-grid-community/react';
import {
  Button,
  ButtonProps,
} from 'components/common/Button';
import {
  ChevronDown,
  DeleteIcon,
  FileIcon,
  FilterIcon,
  HelpIcon,
  MenuIcon,
  PlusIcon,
  ReorderLineIcon,
} from 'assets/icons';
import { AgGridContainer } from 'components/common/AgGridContainer';
import {
  ButtonsContainer,
  Divider,
} from 'components/common/ButtonsContainer';
import { LoadingBar } from 'components/common/LoadingBar';
import { StyledQuantityDisplay } from 'components/Tables/styled';
import {
  CellKeyDownEvent,
  ColDef,
  ColGroupDef,
  ColumnEventType,
  FilterChangedEvent,
  GridOptions,
  IRowNode,
} from '@ag-grid-community/core';
import { commonGridOptions } from 'utils/aggrid';
import { AgGridEmptyState } from 'components/common/AgGridEmptyState';
import { useDragColumnChange } from 'hooks/useDragColumnChange';
import { FactaTableHelp } from 'components/FactaTableHelp';
import { Tooltip } from 'components/common/Tooltip';
import { ANIMATION_DURATION } from 'utils/constants';
import { QuickSearch } from 'components/common/QuickSearch';
import { Dropdown } from 'components/common/Dropdown';
import { ButtonGroup } from 'pages/Reports/ReportConfiguration/styled';
import {
  ActionItem,
  ContextMenu,
} from 'components/ContextMenu';
import useCustomView from 'hooks/useCustomView';
import { ViewsSelector } from './styled';
import { Modal } from 'components/Layout/Modal';
import { AddCustomView } from './AddCustomView';
import { FactaTableView } from 'interfaces/factaTable';
import { format } from 'currency-formatter';
import { getCurrentCompany } from 'utils/currentCompany';
import { useGetAuthMeQuery } from 'store/services/auth';
import { useAppDispatch } from 'hooks/redux';
import { formStateActions } from 'store/slices/formState';
import {
  readDataFromSessionStorage,
  saveDataToSessionStorage,
} from 'utils/sessionStorage';

interface CustomButton extends ButtonProps {
  ref?: any;
  title?: string | undefined;
  onClick?: () => void;
  tooltip?: string;
  hidden?: boolean;
  divider?: boolean;
  icon?: JSX.Element;
}

interface Props {
  gridRef: React.RefObject<AgGridReact>;
  data: any;
  columnDefs: ColDef[] | ColGroupDef[];
  isDisabled?: boolean;
  isFocused?: boolean;
  isLoading?: boolean;
  onClickAdd?: () => void;
  onClickDelete?: () => void;
  selectedRowsLength: number;
  entityName: string;
  gridOptions?: GridOptions;
  dynamicGridOptions?: AgGridReactProps;
  customEmptyState?: () => JSX.Element;
  customAddLabel?: string;
  condensed?: boolean;
  noBordersAndFocus?: boolean;
  suppressQuantityDisplay?: boolean;
  customButtons?: CustomButton[];
  customJSX?: JSX.Element;
  autoHeight?: boolean;
  isDeleteDisabled?: boolean;
  deleteDisabledTooltip?: string;
  onResetView?: () => void;
  onClearAllFilters?: () => void;
  clearAllFiltersExcludeProperty?: string;
  minHeight?: number;
  showQuickSearch?: boolean;
  suppressAddButton?: boolean;
  suppressHeader?: boolean;
  customTitle?: string;
  useNativeFilters?: boolean;
  suppressLoadingOverlay?: boolean;
  views?: FactaTableView[];
  additionalAddOptions?: ActionItem[];
  suppressColumnViews?: boolean;
  suppressNoRowsOverlay?: boolean;
}

export const FactaTable = ({
  gridRef,
  data,
  columnDefs,
  isDisabled,
  isFocused,
  isLoading,
  onClickAdd,
  onClickDelete,
  selectedRowsLength,
  entityName,
  gridOptions,
  dynamicGridOptions,
  customEmptyState,
  customAddLabel,
  condensed,
  noBordersAndFocus,
  suppressQuantityDisplay,
  customButtons,
  customJSX,
  autoHeight,
  isDeleteDisabled,
  deleteDisabledTooltip,
  onResetView,
  onClearAllFilters,
  clearAllFiltersExcludeProperty,
  minHeight,
  showQuickSearch,
  suppressAddButton,
  suppressHeader,
  customTitle,
  useNativeFilters,
  suppressLoadingOverlay,
  views: predefinedViews,
  additionalAddOptions,
  suppressColumnViews,
  suppressNoRowsOverlay,
}: Props) => {
  const dispatch = useAppDispatch();
  const [compactMode, setCompactMode] = useState(localStorage.getItem('compactMode') === 'true');
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [agGridHiddenColumns, setAgGridHiddenColumns] = useState<string[]>([]);
  const [isAddingCustomView, setIsAddingCustomView] = useState(false);
  const [disabledState, setDisabledState] = useState(false);
  const [isColumnsChanged, setIsColumnsChanged] = useState(false);
  const [isAGFilteringApplied, setIsAGFilteringApplied] = useState(false);
  const [isHelpVisible, setIsHelpVisible] = useState(false);
  const [nativeFilteredCount, setNativeFilteredCount] = useState<number | undefined>();
  const { data: user } = useGetAuthMeQuery();
  const currencyCode = getCurrentCompany(user)?.currency.code;

  const {
    onDragStarted,
    onDragStopped,
  } = useDragColumnChange(() => setIsColumnsChanged(true));

  const onFilterChanged = useCallback((e: FilterChangedEvent<any, any>) => {
    const keys = Object.keys(e.api.getFilterModel());
    const hasFilters = keys.filter((key) => key !== clearAllFiltersExcludeProperty).length !== 0;
    setIsAGFilteringApplied(hasFilters);
    saveDataToSessionStorage("filters", user!, e.api.getFilterModel());
  }, [clearAllFiltersExcludeProperty, user]);

  const {
    customViews,
    addCustomView,
    removeCustomView,
  } = useCustomView();

  const views: FactaTableView[] = useMemo(() => ([
    {
      name: 'All Columns',
      hiddenColumns: [],
    },
    ...predefinedViews || [],
    ...customViews]
  ), [customViews, predefinedViews]);

  const currentlySelectedView = views?.find((view) => JSON.stringify(view.hiddenColumns) === JSON.stringify(agGridHiddenColumns));

  const filteredColumnDefs = useMemo(() => {
    return views
      ? columnDefs.map((col) => {
        if (Array.isArray((col as ColGroupDef).children)) {
          return {
            ...col,
            children: (col as ColGroupDef).children.map((child) => ({
              ...child,
              hide: hiddenColumns.length
                ? hiddenColumns.includes((child as ColDef).field!)
                : (child as ColDef).hide || false,
            })),
          };
        } else {
          return {
            ...col,
            hide: hiddenColumns.length ? hiddenColumns.includes((col as ColDef).field!) : (col as ColDef).hide || false,
          };
        }
      })
      : columnDefs;
  }, [columnDefs, hiddenColumns, views]);

  const onCellKeyDown = useCallback(
    (params: CellKeyDownEvent) => {
      const event = params.event as KeyboardEvent;

      // Select all cells in a row on shift+Spacebar
      if (event?.shiftKey && event?.code === 'Space') {
        const rowStartIndex = params.api.getCellRanges()?.[0]?.startRow?.rowIndex || 0;
        const rowEndIndex = params.api.getCellRanges()?.[0]?.endRow?.rowIndex || 0;
        const columns = params.api.getColumns() || [];
        params.api.clearRangeSelection();
        params.api.addCellRange({
          rowStartIndex,
          rowEndIndex,
          columns,
        });

        let nodes: IRowNode[] = [];
        params.api.forEachNodeAfterFilterAndSort((node, index) => {
          if (index >= rowStartIndex && index <= rowEndIndex) {
            nodes.push(node);
          }
        });
        const areAllSelected = nodes.every((node) => node.isSelected());
        params.api.setNodesSelected({ nodes, newValue: !areAllSelected });
      }

      // Select all cells in a column on control+Spacebar
      if (event?.ctrlKey && event?.code === 'Space') {
        const columns = params.api.getCellRanges()?.[0].columns;
        const rowEnd = params.api.getDisplayedRowCount() - 1;
        params.api.clearRangeSelection();
        params.api.addCellRange({
          rowStartIndex: 0,
          rowEndIndex: rowEnd,
          columns,
        });
      }

      // Start editing cell on Spacebar
      if (!params.colDef?.checkboxSelection && params.colDef?.editable && !event?.shiftKey && !event?.ctrlKey && params.rowIndex !== null && event?.key === ' ') {
        params.api.applyTransaction({
          update: [{ ...params.data, [params.column.getColId()]: '' }],
        });
        params.api.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: params.column.getColId(),
        });
      }

      gridOptions?.onCellKeyDown && gridOptions.onCellKeyDown(params);
      dynamicGridOptions?.onCellKeyDown && dynamicGridOptions.onCellKeyDown(params);
    }, [dynamicGridOptions, gridOptions]
  );

  const combinedGridOptions: GridOptions = useMemo(() => ({
    ...commonGridOptions,
    onDragStarted: onDragStarted,
    onDragStopped: onDragStopped,
    onColumnPinned: () => setIsColumnsChanged(true),
    onColumnVisible: (e) => {
      if (e.source !== 'gridOptionsUpdated' as ColumnEventType) {
        setIsColumnsChanged(true);
      }
      setAgGridHiddenColumns(e.api.getColumnState()
        .filter((col) => col.hide)
        .map((col) => col.colId));
    },
    onFilterChanged: (e) => {
      setNativeFilteredCount(e.api.getDisplayedRowCount());
      onFilterChanged(e);
    },
    groupSelectsChildren: true,
    sideBar: useNativeFilters ? { toolPanels: ['filters'] } : false,
    columnTypes: {
      currency: {
        valueFormatter: ({ value }) => format(value, { code: currencyCode }),
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell',
      },
      currencyOfMatchedValue: {
        valueFormatter: ({ value }) => value.matchedValue !== null ? format(value.matchedValue, { code: currencyCode }) : '-',
        headerClass: 'ag-right-aligned-header',
        cellClass: 'ag-right-aligned-cell',
      },
    },
    ...gridOptions,
    onSelectionChanged: (e) => {
      gridOptions?.onSelectionChanged && gridOptions.onSelectionChanged(e);
      dispatch(formStateActions.clear());
    },
    onRowDataUpdated: (e) => {
      dynamicGridOptions?.onRowDataUpdated && dynamicGridOptions.onRowDataUpdated(e);
      const filters = readDataFromSessionStorage("filters", user!);
      gridRef.current?.api.setFilterModel(filters || null);
    },
    onFirstDataRendered: (e) => {
      gridOptions?.onFirstDataRendered && gridOptions.onFirstDataRendered(e);
      dispatch(formStateActions.clear());
    },
    defaultColDef: {
      suppressKeyboardEvent: (params) => {
        // Allow Shift+Spacebar to select all cells in a row
        if (params.event.key === ' ' && params.event.shiftKey) {
          return true;
        }
        // Suppress keyboard Spacebar event on editable cells
        if (!!params.colDef?.editable && params.event.key === ' ') {
          return true;
        }
        return false;
      },
      ...gridOptions?.defaultColDef,
    },
  }), [currencyCode, dispatch, dynamicGridOptions, gridOptions, gridRef, onDragStarted, onDragStopped, onFilterChanged, useNativeFilters, user]);

  const handleToggleNativeFilter = () => {
    if (gridRef.current?.api.isToolPanelShowing()) {
      gridRef.current?.api.closeToolPanel();
    } else {
      gridRef.current?.api.openToolPanel('filters');
    }
  };

  const handleClickResetView = useCallback(() => {
    gridRef.current!.api.resetColumnState();
    onResetView && onResetView();
    setTimeout(() => {
      setIsColumnsChanged(false);
    }, 0);
  }, [gridRef, onResetView]);

  const handleClearAGFiltering = () => {
    gridRef.current?.api.setFilterModel(null);
    gridRef.current?.api.closeToolPanel();
    onClearAllFilters && onClearAllFilters();
  };

  const handleSearchChange = (search: string) => {
    gridRef.current?.api?.setGridOption('quickFilterText', search);
  };

  const NoRowsOverlay = () => (
    <AgGridEmptyState>
      You don’t have any {entityName.toLowerCase()}s set up yet.
      {' '}
      {customAddLabel || `Add a ${entityName.toLowerCase()}`} a to get started.
      {onClickAdd && (
        <Button
          variant="contained"
          size="normal"
          onClick={onClickAdd}
        >
          {customAddLabel ? customAddLabel.toUpperCase() : `ADD ${entityName.toUpperCase()}`}
        </Button>
      )}
    </AgGridEmptyState>
  );

  const LoadingOverlay = () => (
    <AgGridEmptyState>
      Loading...
    </AgGridEmptyState>
  );

  useEffect(() => {
    if (gridRef.current?.api) {
      if (isLoading && !suppressLoadingOverlay) {
        gridRef.current.api.showLoadingOverlay();
      } else if (data && data.length === 0) {
        setTimeout(() => gridRef?.current?.api.showNoRowsOverlay(), 0);
      } else {
        gridRef.current.api.hideOverlay();
      }
    }
  }, [gridRef, isLoading, data, suppressLoadingOverlay]);

  useEffect(() => {
    setTimeout(() => {
      setDisabledState(!!isDisabled);
    }, ANIMATION_DURATION);
  }, [isDisabled]);

  useEffect(() => {
    // Views integrity check

    if (!columnDefs.length) return;

    const columnCheck = (col: string) => columnDefs.find((c) => {
      if (Array.isArray((c as ColGroupDef).children)) {
        return (c as ColGroupDef).children.find((child) => (child as ColDef).field === col);
      } else {
        return (c as ColDef).field === col;
      }
    });

    customViews.forEach((view) => {
      const isInvalid = !view.hiddenColumns.every(columnCheck);
      if (isInvalid) {
        const newHiddenColumns = view.hiddenColumns.filter(columnCheck);
        if (newHiddenColumns.length) {
          addCustomView({ name: view.name, hiddenColumns: newHiddenColumns });
        } else {
          removeCustomView(view.name);
          setHiddenColumns([]);
        }
      }
    });
  }, [addCustomView, columnDefs, customViews, removeCustomView, user]);

  useEffect(() => {
    if (customViews && user && !!columnDefs.length) {
      const view = readDataFromSessionStorage("view", user);

      if (view) {
        const hiddenColumns = customViews.find((v) => v.name === view)?.hiddenColumns || [];
        setHiddenColumns(hiddenColumns);
      }
    }
  }, [customViews, user, columnDefs]);

  useEffect(() => {
    localStorage.setItem('compactMode', compactMode ? 'true' : 'false');
  }, [compactMode]);

  return (
    <>
      <ButtonsContainer hidden={noBordersAndFocus || suppressHeader}>
        {customTitle && customTitle}
        {!customTitle && suppressQuantityDisplay && (
          <StyledQuantityDisplay>
            {`${selectedRowsLength} ${entityName}${selectedRowsLength !== 1 ? 's' : ''} selected`}
          </StyledQuantityDisplay>
        )}
        {(!suppressQuantityDisplay && !useNativeFilters) && (
          <StyledQuantityDisplay>
            {selectedRowsLength > 0 && `${selectedRowsLength} / `}
            {data?.length || 0} {entityName}{data?.length === 1 ? '' : 's'}
          </StyledQuantityDisplay>
        )}
        {!suppressQuantityDisplay && useNativeFilters && (
          <StyledQuantityDisplay>
            {selectedRowsLength > 0 && `${selectedRowsLength} / `}
            {nativeFilteredCount !== undefined ? nativeFilteredCount : data?.length}{' '}
            {entityName}{data?.length === 1 ? '' : 's'}
          </StyledQuantityDisplay>
        )}
        {useNativeFilters && (
          <>
            <Divider />
            <Button
              variant={isAGFilteringApplied ? 'outlined' : "borderless"}
              size="large"
              onClick={handleToggleNativeFilter}
            >
              <FilterIcon />
              {isAGFilteringApplied ? 'Filters applied' : 'Filters'}
            </Button>
          </>
        )}
        {isAGFilteringApplied && (
          <Button
            variant="borderless"
            color="primary"
            size="large"
            onClick={handleClearAGFiltering}
          >
            Clear All Filters
          </Button>
        )}
        {onClickDelete && !!selectedRowsLength && (
          <>
            <Divider />
            <Tooltip title={isDeleteDisabled ? deleteDisabledTooltip : ''}>
              <Button
                variant="borderless"
                color="error"
                size="large"
                onClick={onClickDelete}
                disabled={isDeleteDisabled}
              >
                <DeleteIcon />
                Delete ({selectedRowsLength})
              </Button>
            </Tooltip>
          </>
        )}
        {isColumnsChanged && (
          <>
            <Divider />
            <Button
              variant="borderless"
              color="primary"
              size="large"
              onClick={handleClickResetView}
            >
              Reset View
            </Button>
          </>
        )}
        <Divider />
        <Tooltip title="Advanced table features">
          <Button
            variant="icon"
            color="primary"
            size="large"
            onClick={() => setIsHelpVisible(true)}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
        {showQuickSearch && (
          <>
            <Divider />
            <QuickSearch
              inline
              handleSearchChange={handleSearchChange}
            />
          </>
        )}
        {customJSX}
        <Divider />
        <Tooltip title={compactMode ? 'Switch to Default Density' : 'Switch to Compact Density'}>
          <Button
            variant="icon"
            color="primary"
            onClick={() => setCompactMode(!compactMode)}
          >
            {compactMode ? <ReorderLineIcon /> : <MenuIcon />}
          </Button>
        </Tooltip>
        {customButtons?.filter(({ hidden }) => !hidden)
          .map(({ divider, title, icon, tooltip, hidden, onClick, pushRight, ...props }, index) => onClick
            ? (
              <React.Fragment key={`cb_${title || index}`}>
                {divider && <Divider />}
                <Tooltip title={tooltip}>
                  <Button
                    {...props}
                    pushRight={pushRight}
                    onClick={onClick}
                  >
                    {icon && icon}
                    {title}
                  </Button>
                </Tooltip>
              </React.Fragment>
            )
            : (
              <span
                key={`cb_${title || index}`}
                style={pushRight ? { marginLeft: 'auto ' } : {}}
              >
                {title}
              </span>
            ),
          )}
        {views && !suppressColumnViews && (
          <ViewsSelector hasButton={!!customViews!.find((view) => currentlySelectedView?.name === view.name) || !currentlySelectedView}>
            <span>Columns:</span>
            <Dropdown
              values={currentlySelectedView ? [currentlySelectedView] : []}
              options={views}
              labelField="name"
              valueField="name"
              onChange={(value) => {
                if (!value.at(0)) return;
                const hiddenColumns = value.at(0).hiddenColumns || [];
                setHiddenColumns(hiddenColumns);
                saveDataToSessionStorage("view", user!, value.at(0).name);
              }}
              condensed
              searchable={false}
              minWidth={180}
            />
            <Tooltip title="Save current view as new...">
              <Button
                variant="icon"
                color="primary"
                onClick={() => setIsAddingCustomView(true)}
                hidden={!!currentlySelectedView}
              >
                <FileIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Delete current view">
              <Button
                variant="icon"
                color="error"
                hidden={!customViews!.find((view) => currentlySelectedView?.name === view.name)}
                onClick={() => {
                  removeCustomView(currentlySelectedView!.name);
                  setHiddenColumns(predefinedViews?.at(0)?.hiddenColumns || []);
                }}
              >
                <DeleteIcon />
              </Button>
            </Tooltip>
          </ViewsSelector>
        )}
        {onClickAdd && additionalAddOptions && (
          <ButtonGroup>
            <Button
              onClick={() => onClickAdd()}
              variant="contained"
              minWidth={144}
            >
              {customAddLabel || `Add ${entityName}`}
            </Button>
            <ContextMenu
              items={additionalAddOptions}
              customIcon={<ChevronDown />}
              variant="contained"
            />
          </ButtonGroup>
        )}
        {onClickAdd && !additionalAddOptions && !suppressAddButton && (
          <Button
            variant="borderless"
            size="large"
            pushRight={views ? false : true}
            onClick={onClickAdd}
            disabled={disabledState}
          >
            <PlusIcon />
            <span>
              {customAddLabel || `Add ${entityName}`}
            </span>
          </Button>
        )}
      </ButtonsContainer>
      <AgGridContainer
        $disabled={disabledState}
        isFocused={isFocused}
        condensed={condensed}
        noBordersAndFocus={noBordersAndFocus}
        className="ag-theme-material"
        autoHeight={autoHeight}
        minHeight={minHeight}
        onKeyDown={(e) => {
          // Prevent Spacebar from scrolling the page
          if ((e.target as Element).role === 'gridcell') {
            e.preventDefault();
          }
        }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={data}
          columnDefs={filteredColumnDefs}
          gridOptions={combinedGridOptions}
          noRowsOverlayComponent={customEmptyState || NoRowsOverlay}
          loadingOverlayComponent={LoadingOverlay}
          domLayout={autoHeight ? 'autoHeight' : 'normal'}
          rowHeight={compactMode ? 30 : 48}
          headerHeight={compactMode ? 40 : 48}
          {...dynamicGridOptions}
          getRowHeight={(params) => params.node.group && params.node.level === 0
            ? compactMode
              ? 40
              : 48
            : undefined
          }
          onCellKeyDown={onCellKeyDown}
          suppressNoRowsOverlay={suppressNoRowsOverlay}
        />
        {isLoading && <LoadingBar isWithinTable />}
      </AgGridContainer>
      {isHelpVisible && (
        <FactaTableHelp
          columnDefs={columnDefs}
          onClose={() => setIsHelpVisible(false)}
          gridOptions={combinedGridOptions}
        />
      )}
      <Modal
        isOpen={isAddingCustomView}
        onClose={() => setIsAddingCustomView(false)}
        maxWidth={500}
      >
        <AddCustomView
          onClose={() => setIsAddingCustomView(false)}
          onSave={(name: string) => {
            setIsAddingCustomView(false);
            addCustomView({ name, hiddenColumns: agGridHiddenColumns });
          }}
        />
      </Modal>
    </>
  );
};
