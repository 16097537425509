import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FactaTable } from 'components/FactaTable';
import {
  LeftPane,
  RightPane,
} from 'components/Layout';
import { StyledFormContainer } from 'components/common/Forms/styled';
import { Dropdown } from 'components/common/Dropdown';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import { AgGridReact } from '@ag-grid-community/react';
import { useGetSDRDataQuery } from 'store/services/salesComissions';
import {
  SDRDetail,
  SDRCommission,
} from 'interfaces/commissions';
import { getColumnDefs } from './columnDefinitions';
import { GridOptions } from '@ag-grid-community/core';
import { DetailBoxContainer } from 'components/DetailBox/styled';
import {
  DetailBox,
  DetailBoxEntry,
} from 'components/DetailBox';
import { format } from 'currency-formatter';
import { SliderWrapper } from 'components/DetailTable/styled';
import { StyledSliderContainer } from 'components/FactaChart/styled';
import Slider from 'rc-slider';
import { useGetReportPeriodsQuery } from 'store/services/reports';
import { chartDateFormatter } from 'utils/charts';
import { useGetSettingsQuery } from 'store/services/settings';
import { currentFiscalYearStartMonth } from 'utils/dates';
import { useGetAuthMeQuery } from 'store/services/auth';
import { getCurrentCompany } from 'utils/currentCompany';

export const SDRCommissions = () => {
  const gridRef = useRef<AgGridReact>(null);
  const [tempRange, setTempRange] = useState<number[] | null>(null);
  const { data: user } = useGetAuthMeQuery();
  const { data: settings } = useGetSettingsQuery();
  const [range, setRange] = useState(tempRange);
  const { data: availablePeriods } = useGetReportPeriodsQuery();
  const { data, isFetching: isLoading } = useGetSDRDataQuery({
    startDate: range ? availablePeriods?.['monthly']?.[range[0]] : undefined,
    endDate: range ? availablePeriods?.['monthly']?.[range[1]] : undefined,
  }, {
    skip: !range || range[1] < 0 || !availablePeriods || !settings,
  });
  const columnDefs = getColumnDefs();
  const currencyCode = getCurrentCompany(user)?.currency.code || 'USD';

  const SalesRepOptions = useMemo(() => (data?.salesReps.flatMap((rep) => ({ name: rep }))) || [], [data]);

  const {
    getValues,
    control,
    watch,
    setValue,
  } = useForm<{ salesRep: { name: string } }>({
    mode: 'onChange',
    defaultValues: {
      salesRep: { name: '' },
    },
  });

  const {
    salesRep,
  } = watch();

  const gridData: SDRCommission[] = useMemo(() => (data?.commissions[salesRep.name] || []), [data, salesRep]);
  const details: SDRDetail = useMemo(() => (data?.values[salesRep.name] || { closedSQLs: 0, earnedSQLCommissions: 0, totalEarnedCommissions: 0 }), [data, salesRep]);

  const gridOptions: GridOptions = {
    getRowId: (params) => params.data.commissionID,
  };

  useEffect(() => {
    if (SalesRepOptions.length) {
      setValue('salesRep', SalesRepOptions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SalesRepOptions.length, setValue]);

  const periods = useMemo(() => {
    return availablePeriods?.monthly || [];
  }, [availablePeriods]);

  const sliderMax = useMemo(() => (periods.length || 1) - 1, [periods.length]);

  const sliderMarks = useMemo(() => {
    return data
      ? periods.reduce((a, v, index) => ({ ...a, [index]: chartDateFormatter(v) }), {})
      : {};
  }, [data, periods]);

  useEffect(() => {
    const fiscalMonthIndex = settings?.fiscalYearEnd ? periods.indexOf(currentFiscalYearStartMonth(settings?.fiscalYearEnd)) : 12;
    const lastN = periods.length - fiscalMonthIndex;
    setRange([Math.max(0, periods.length - lastN), periods.length - 1]);
    setTempRange([Math.max(0, periods.length - lastN), periods.length]);
  }, [periods, settings?.fiscalYearEnd]);

  return (
    <>
      <LeftPane>
        <ButtonsContainer>
          <Breadcrumbs>
            <Breadcrumb>Dashboards</Breadcrumb>
            <Breadcrumb>SDR Commissions</Breadcrumb>
          </Breadcrumbs>
        </ButtonsContainer>
        <ButtonsContainer>
          <SectionLabel condensed>
            Sales Development Representative Commission Statement
          </SectionLabel>
          <Spacer />
          <SliderWrapper width={((availablePeriods?.monthly.length || 1) * 10) + 40}>
            <StyledSliderContainer>
              <Slider
                range
                min={0}
                max={sliderMax}
                marks={sliderMarks}
                onChange={(values) => setTempRange(values as number[])}
                onChangeComplete={(values) => setRange(values as number[])}
                draggableTrack
                value={tempRange!}
                disabled={isLoading}
              />
            </StyledSliderContainer>
          </SliderWrapper>
        </ButtonsContainer>
        <DetailBoxContainer>
          <DetailBox title="Quota Retired">
            <DetailBoxEntry
              title="Closed SQLs per Fiscal Year"
              value={`${details.closedSQLs}`}
            />
          </DetailBox>
          <DetailBox
            title="Results for current period"
            flex
          >
            <DetailBoxEntry
              title="Earned SQL Commissions"
              value={format(details.earnedSQLCommissions, { code: currencyCode })}
            />
            <DetailBoxEntry
              title="Total Earned Commissions"
              value={format(details.totalEarnedCommissions, { code: currencyCode })}
            />
          </DetailBox>
        </DetailBoxContainer>
        <FactaTable
          gridRef={gridRef}
          data={gridData}
          gridOptions={gridOptions}
          columnDefs={columnDefs}
          selectedRowsLength={0}
          entityName="commisions"
          suppressAddButton
          suppressQuantityDisplay
          customTitle="Sales Development Representative Commission Statement"
          condensed
          isLoading={isLoading}
        />
      </LeftPane>
      <RightPane
        isOpen
        fixed
        collapsible
      >
        <header />
        <main>
          <StyledFormContainer>
            <ButtonsContainer>
              <SectionLabel
                tertiary
                marginBottom={0}
              >
                Options
              </SectionLabel>
              <Spacer />
            </ButtonsContainer>
            <Controller
              name="salesRep"
              control={control}
              render={({ field }) =>
                <Dropdown
                  {...field}
                  labelText="SDR Name"
                  options={SalesRepOptions || []}
                  labelField="name"
                  valueField="name"
                  searchBy="name"
                  placeholder="Select Rep Name"
                  values={[getValues('salesRep')]}
                  onChange={(v) => v.length ? field.onChange(v[0]) : undefined}
                  condensed
                />
              }
            />
          </StyledFormContainer>
        </main>
        <footer />
      </RightPane>
    </>
  );
};
