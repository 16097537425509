import { DetailTableOptions } from "interfaces/revenueDetail";

export const CohortsMetricsOptions: DetailTableOptions = [
  { name: 'Revenue', value: 'revenue' },
  { name: 'New', value: 'new' },
  { name: 'Upgrade', value: 'upgrade' },
  { name: 'New + Upgrade', value: 'newUpgrade' },
  { name: 'Downgrade', value: 'downgrade' },
  { name: 'Net Expansion', value: 'netExpansion' },
  { name: 'Cancelled', value: 'cancelled' },
  { name: 'Net New', value: 'netNew' },
  { name: 'Reactivated', value: 'reactivated' },
  { name: 'Deactivated', value: 'deactivated' },
  { name: 'New Excl Reactivated', value: 'newExclReactivated' },
  { name: 'Cancelled Excl Deactivated', value: 'cancelledExclDeactivated' },
  { name: 'New Logos', value: 'newLogos', dontMultiply: true },
  { name: 'Cancelled Logos', value: 'cancelledLogos', dontMultiply: true },
  { name: 'Active Logos', value: 'activeLogos', dontMultiply: true },
  { name: 'Reactivated Logos', value: 'reactivatedLogos', dontMultiply: true },
  { name: 'Deactivated Logos', value: 'deactivatedLogos', dontMultiply: true },
  { name: 'New Excl Reactivated Logos', value: 'newExclReactivatedLogos', dontMultiply: true },
  { name: 'Churned Excl Deactivated Logos', value: 'churnedExclDeactivatedLogos', dontMultiply: true },
];

export const CohortDimensionOptionsContracts = [
  { name: 'Customer Start Date' },
  { name: 'Customer' },
  { name: 'Customer Tags' },
  { name: 'Product' },
  { name: 'Product Tags' },
  { name: 'Contract' },
  { name: 'Contract Tags' },
  { name: 'Contract Line Tags' },
  { name: 'None' },
];

export const CohortDimensionOptionsSubscriptions = [
  { name: 'Customer Start Date' },
  { name: 'Customer' },
  { name: 'Customer Tags' },
  { name: 'Product' },
  { name: 'Product Tags' },
  { name: 'Subscription Tags' },
  { name: 'None' },
];

export const CohortTypeOptions = [
  { name: 'By Period', value: 'byPeriod' },
  { name: 'Since Inception', value: 'byInception' },
];
