import { useEffect } from 'react';
import { LeftPane } from 'components/Layout';
import {
  SectionLabel,
  SectionLabelPill,
} from 'components/common/SectionLabel';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import { useAppDispatch } from 'hooks/redux';
import { ReportsTags } from 'store/services/api-tags';
import { invalidateTags } from 'store/services/api';
import {
  LibraryIcon,
  ListIcon,
  SettingsIcon,
  TagIcon,
} from 'assets/icons';
import {
  BoxesContainer,
  LeftBox,
  RightContainer,
  SectionLabelPillsWrapper,
  StyledItem,
} from './styled';
import { FormulaEditor } from 'components/FormulaEditor';
import {
  getAggregationMethodName,
  metricStartDateOptions,
} from 'utils/metrics';
import {
  useGetAllMetricsQuery,
  useGetMetricDependantsQuery,
  useGetMetricQuery,
} from 'store/services/metrics';
import { Tooltip } from 'components/common/Tooltip';
import {
  useNavigate,
  useParams,
} from 'react-router';
import { Modal } from 'components/Layout/Modal';
import { EditMetric } from '../EditMetric';
import { useRightPanes } from 'hooks/useRightPanes';
import { ContextMenu } from 'components/ContextMenu';
import {
  MetricCategory,
  MetricOrigin,
} from 'interfaces/metrics';
import { DuplicateMetrics } from '../DuplicateMetrics';
import { MetricFiltersComponent } from '../MetricFilters';
import { Loading } from 'components/Loading';
import { MetricFilteredBy } from '../FilteredBy';
import { MetricChart } from '../MetricChart';
import { Link } from 'react-router-dom';
import { ManualMetricTable } from '../ManualMetricTable';
import { featureSwitch } from 'utils/featureSwitch';
import { Box } from 'components/common/Box';
import { DeleteMetric } from '../DeleteMetric';

export const MetricConfiguration = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { metricId = '' } = useParams<{ metricId: string }>();
  const { data: metric, isLoading: isLoadingMetric } = useGetMetricQuery(metricId, { refetchOnMountOrArgChange: true });
  const { data: metrics } = useGetAllMetricsQuery();
  const { data: dependants } = useGetMetricDependantsQuery(metricId);

  const { rightPanes, setSelectedPane, resetPanes } = useRightPanes({
    edit: false,
    duplicate: false,
    filters: false,
    delete: false,
  });

  const variations = metrics?.filter((m) => m.type.id === metric?.type.id && m.id !== metric.id);

  useEffect(() => () => {
    dispatch(invalidateTags([ReportsTags.Report]));
  }, [dispatch]);

  if (isLoadingMetric) return (
    <LeftPane>
      <Loading />
    </LeftPane>
  );

  if (!isLoadingMetric && !metric) return (
    <LeftPane>
      No metric found or error fetching metric.
    </LeftPane>
  );

  const shouldShowFilters = metric?.origin !== MetricOrigin.CUSTOM
    && metric?.origin !== MetricOrigin.MANUAL
    && metric?.type.category !== MetricCategory.PL
    && metric?.type.category !== MetricCategory.BS;

  return (
    <>
      <LeftPane
        secondary
        contentWidth={1800}
      >
        <Breadcrumbs>
          <Breadcrumb link="/metrics">
            Metrics
          </Breadcrumb>
          <Breadcrumb>
            {`${metric?.name || '-'}`}
          </Breadcrumb>
        </Breadcrumbs>
        <SectionLabel marginBottom={16}>
          <SectionLabelPillsWrapper>
            {metric?.name || '-'}
            {metric?.origin === MetricOrigin.SYSTEM && (
              <Tooltip
                title="System Metric allows you to assign custom tags and Lookback Period filter to it.
                  To access other filters (Revenue Type, Customer, Product), please create a duplicate of this metric.
                  To provide custom formula, please create a Custom Metric.">
                <SectionLabelPill>System</SectionLabelPill>
              </Tooltip>
            )}
            {metric?.origin === MetricOrigin.SYSTEM_COPY && (
              <Tooltip
                title="Copy of a System Metric allows you to assign custom tags and all available filters (Lookback Period,
                  Revenue Type, Customer, Product) to it. To provide custom formula, please create a Custom Metric.">
                <SectionLabelPill secondary>System Copy</SectionLabelPill>
              </Tooltip>
            )}
            {metric?.origin === MetricOrigin.CUSTOM && (
              <Tooltip
                title="Custom Metric allows you to provide your custom formula, based on other Metrics and Accounts.
                  You won't be able to apply any filters to it.">
                <SectionLabelPill tertiary>Custom Metric</SectionLabelPill>
              </Tooltip>
            )}
          </SectionLabelPillsWrapper>
        </SectionLabel>
        <BoxesContainer>
          <LeftBox>
            <SectionLabel tertiary>
              <span>
                Metric Info
              </span>
              <ContextMenu
                customIcon={<SettingsIcon />}
                largeIcon
                color="primary"
                items={[
                  {
                    name: 'Edit Metric',
                    action: () => setSelectedPane('edit'),
                  },
                  {
                    name: 'Duplicate Metric',
                    action: () => setSelectedPane('duplicate'),
                  },
                  {
                    name: 'Delete Metric',
                    action: () => setSelectedPane('delete'),
                  },
                ]} />
            </SectionLabel>
            <StyledItem>
              <title>Metric Name</title>
              {metric?.name}
            </StyledItem>
            <StyledItem>
              <title>Description</title>
              {metric?.description || metric?.type.description || 'N/A'}
            </StyledItem>
            <StyledItem>
              <title>Metric Category</title>
              {metric?.type.category}
            </StyledItem>
            <StyledItem>
              <title>Metric Type</title>
              {metric?.type.name}
            </StyledItem>
            {metric?.origin !== MetricOrigin.MANUAL && (
              <StyledItem>
                <title>Lookback Period</title>
                {metric?.filters.lookback
                  ? metric?.filters.lookback.period
                    ? `${metric?.filters.lookback.period} months`
                    : 'YTD'
                  : '-'}
              </StyledItem>
            )}
            <StyledItem>
              <title>Metric Start Date</title>
              {metricStartDateOptions.filter((o) => o.value === metric?.startDateField)[0].name}
            </StyledItem>
            <StyledItem>
              <title>Aggregation Method</title>
              {metric ? getAggregationMethodName(metric?.aggregationMethod) : '-'}
            </StyledItem>
            <StyledItem>
              <title>Tags</title>
              {!metric?.tags && 'N/A'}
              {metric?.tags && (
                <ul>
                  {metric.tags.map((tag) => (
                    <li key={`tag_${tag}`}><TagIcon /> {tag}</li>
                  ))}
                </ul>
              )}
            </StyledItem>
            {metric?.type.name !== 'Custom' && metric?.type.name !== 'Manual' && (
              <StyledItem>
                <title>
                  Variations
                </title>
                {variations?.length === 0 && 'N/A'}
                {!!variations?.length && (
                  <ul>
                    {variations?.map((m) => (
                      <li key={`var_${m.id}`}>
                        <span>
                          <Link to={`/metrics/${m.id}`}>{m.name}</Link>
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </StyledItem>
            )}
            <StyledItem>
              <title>
                Dependants
                <Tooltip
                  position="top"
                  title={`This Metric is used in the following
                    ${!!dependants?.metricDependants.length ? 'metrics' : ''}
                    ${!!dependants?.metricDependants.length && !!dependants?.reportDependants.length ? ' and' : ''}
                    ${!!dependants?.reportDependants.length ? ' reports' : ''}.`}
                />
              </title>
              {dependants?.metricDependants.length === 0 && dependants.reportDependants.length === 0 && 'N/A'}
              {!!dependants?.metricDependants.length && (
                <ul>
                  {dependants?.metricDependants.map((m) => (
                    <li key={`mdep_${m.id}`}>
                      <ListIcon />
                      <span>
                        <Link to={`/metrics/${m.id}`}>{m.name}</Link>
                      </span>
                    </li>
                  ))}
                </ul>
              )}
              {!!dependants?.reportDependants.length && (
                <ul>
                  {dependants?.reportDependants.map((r) => (
                    <li key={`rdep_${r.id}`}>
                      <LibraryIcon />
                      <span>
                        <Link to={`/reports/${r.id}`}>{r.name}</Link>
                      </span>
                    </li>
                  ))}
                </ul>
              )}
            </StyledItem>
          </LeftBox>
          <RightContainer>
            {metric?.origin === MetricOrigin.MANUAL && !featureSwitch.manualMetrics && (
              <Box>Entering values for manual metrics is not yet supported. Coming soon.</Box>
            )}
            {metric?.origin === MetricOrigin.MANUAL && featureSwitch.manualMetrics && (
              <ManualMetricTable metricId={metricId} />
            )}
            {metric?.origin === MetricOrigin.CUSTOM && (
              <FormulaEditor metricId={metric.id} />
            )}
            {shouldShowFilters && (
              <MetricFilteredBy
                metric={metric!}
                onEdit={() => setSelectedPane('filters')}
              />
            )}
            <MetricChart metricId={metricId} />
          </RightContainer>
        </BoxesContainer>
      </LeftPane>
      <Modal
        isOpen={rightPanes.edit}
        onClose={() => resetPanes()}
      >
        <EditMetric
          metricId={metricId}
          onClose={() => resetPanes()}
        />
      </Modal>
      <Modal
        isOpen={rightPanes.filters}
        onClose={() => resetPanes()}
        suppressOverflow
      >
        <MetricFiltersComponent
          metricId={metricId}
          onClose={() => resetPanes()}
        />
      </Modal>
      <Modal
        isOpen={rightPanes.duplicate}
        onClose={() => resetPanes()}
        condensed
      >
        <DuplicateMetrics
          selectedMetrics={[metric!]}
          onClose={() => resetPanes()}
          openedFromMetric
        />
      </Modal>
      <Modal
        isOpen={rightPanes.delete}
        onClose={() => resetPanes()}
        condensed
        maxWidth={500}
      >
        <DeleteMetric
          selectedMetrics={[metric!]}
          onClose={() => resetPanes()}
          onSuccess={() => navigate('/metrics')}
        />
      </Modal>
    </>
  );
};
