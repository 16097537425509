import {
  AddMetricRequest,
  BulkTagsRequest,
  DuplicateMetricsRequest,
  Metric,
  MetricDependants,
  MetricOrigin,
  MetricTypes,
  MetricWithDetails,
  Metrics,
  MetricsSummary,
  MetricsWithValues,
  UpdateMetricRequest,
  UploadManualsRequest,
} from "interfaces/metrics";
import { api } from "./api";
import {
  FormulaValidateRequest,
  FormulaValidateResponse,
} from "interfaces/formulaEditor";
import { apiAddRecipe } from "utils/api";
import {
  CommonMetricTags,
  MetricsTags,
  ReportsTags,
} from "./api-tags";
import {
  ChartBackendRequest,
  ChartBackendResponse,
  ChartConfigUpdateRequest,
} from "interfaces/charts";
import {
  RevenueDetailsSearchResponse,
  RevenueDetailsRequest,
} from "interfaces/revenueDetail";

export const metricsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    syncMetrics: builder.mutation<void, void>({
      query: () => ({
        url: 'metrics/sync',
        method: 'POST',
      }),
      invalidatesTags: (res, err) => !err ? [
        ...CommonMetricTags,
      ] : [],
    }),
    getAllMetrics: builder.query<Metrics, void>({
      query: () => ({
        url: 'metrics',
      }),
      providesTags: [MetricsTags.Metrics],
    }),
    getMetric: builder.query<MetricWithDetails, string>({
      query: (id) => ({
        url: `metrics/${id}`,
      }),
      providesTags: [MetricsTags.Metric],
    }),
    getAllMetricTypes: builder.query<MetricTypes, void>({
      query: () => ({
        url: 'metric_types',
        omitCompanyId: true,
      }),
    }),
    addMetric: builder.mutation<Metric, AddMetricRequest>({
      query: (body) => ({
        method: 'POST',
        url: 'metrics',
        body,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data: addedMetric } = await queryFulfilled;
          dispatch(metricsApi.util
            .updateQueryData('getAllMetrics', undefined, apiAddRecipe(addedMetric)));
        } catch {}
      },
    }),
    updateMetric: builder.mutation<MetricWithDetails, UpdateMetricRequest>({
      query: ({ metricId, ...rest }) => ({
        method: 'PUT',
        url: `metrics/${metricId}`,
        body: rest,
      }),
      invalidatesTags: (res, err) => !err ? [
        ...CommonMetricTags,
      ] : [],
      onQueryStarted: async ({ metricId }, { dispatch, queryFulfilled }) => {
        try {
          const { data: updatedMetric } = await queryFulfilled;
          dispatch(metricsApi.util
            .updateQueryData('getMetric', metricId, () => updatedMetric));
        } catch {}
      },
    }),
    postValidateFormula: builder.mutation<FormulaValidateResponse, FormulaValidateRequest>({
      query: ({ metricId, formula }) => ({
        method: 'POST',
        url: `metrics/${metricId}/validate_formula`,
        body: formula,
      }),
    }),
    postSaveFormula: builder.mutation<FormulaValidateResponse, FormulaValidateRequest>({
      query: ({ metricId, formula }) => ({
        method: 'PUT',
        url: `metrics/${metricId}/formula`,
        body: formula,
      }),
      invalidatesTags: (res, err) => !err ? [
        ...CommonMetricTags,
      ] : [],
      onQueryStarted: async ({ metricId, formula }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(metricsApi.util
            .updateQueryData('getMetric', metricId, (metric) => ({
              ...metric,
              formula,
            })));
        } catch {}
      },
    }),
    postDuplicateMetrics: builder.mutation<string[], DuplicateMetricsRequest>({
      query: (body) => ({
        method: 'POST',
        url: 'metrics/bulk_duplicate',
        body,
      }),
      invalidatesTags: (res, err) => !err ? [
        MetricsTags.Metrics,
        MetricsTags.MetricsWithValues,
      ] : [],
    }),
    postBulkTags: builder.mutation<string[], BulkTagsRequest>({
      query: (body) => ({
        method: 'POST',
        url: 'metrics/bulk_tags',
        body,
      }),
      invalidatesTags: (res, err) => !err ? [
        MetricsTags.Metrics,
        MetricsTags.MetricsWithValues,
        MetricsTags.Metric,
      ] : [],
    }),
    getMetricChart: builder.query<ChartBackendResponse, ChartBackendRequest>({
      query: ({ id }) => ({
        url: `metrics/${id}/chart`,
      }),
      providesTags: [MetricsTags.Chart],
    }),
    updateMetricChart: builder.mutation<void, ChartConfigUpdateRequest>({
      query: ({ id, ...rest }) => ({
        method: 'PUT',
        url: `metrics/${id}/format`,
        body: rest,
      }),
      invalidatesTags: (res, err) => !err ? [
        ReportsTags.Report,
        ReportsTags.Reports,
        MetricsTags.MetricsWithValues,
        MetricsTags.MetricsSummary,
      ] : [],
    }),
    postRevenueDetails: builder.query<RevenueDetailsSearchResponse, RevenueDetailsRequest >({
      query: (body) => ({
        method: 'POST',
        url: 'metrics/revenue_details',
        body,
      }),
      providesTags: [MetricsTags.RevenueDetail],
      keepUnusedDataFor: 0,
    }),
    putManualMetrics: builder.mutation<void, UploadManualsRequest>({
      query: (body) => ({
        method: 'PUT',
        url: `metrics/upload_manuals`,
        body,
      }),
      invalidatesTags: (res, err) => !err ? [
        ...CommonMetricTags,
      ] : [],
    }),
    getMetricsWithValues: builder.query<MetricsWithValues, {
      metricId: string;
      origin?: MetricOrigin;
      startDate?: string;
      endDate?: string;
      omitSettingsDates?: string;
    }>({
      query: (params) => ({
        url: 'metrics/values',
        params,
      }),
      providesTags: [MetricsTags.MetricsWithValues],
    }),
    getOneMetricWithValues: builder.query<MetricsWithValues, {
      metricId: string;
      origin?: MetricOrigin;
      startDate?: string;
      endDate?: string;
      omitSettingsDates?: string;
    }>({
      query: (params) => ({
        url: 'metrics/values',
        params,
      }),
      providesTags: [MetricsTags.OneMetricWithValues],
    }),
    getMetricDependants: builder.query<MetricDependants, string>({
      query: (id) => ({
        url: `metrics/${id}/dependants`,
      }),
      providesTags: [MetricsTags.MetricDependants],
    }),
    deleteMetric: builder.mutation<MetricDependants, { id: string; force: boolean }>({
      query: ({ id, force }) => ({
        method: 'DELETE',
        url: `metrics/${id}`,
        params: {
          force: force ? 'true' : 'false',
        },
      }),
      invalidatesTags: (res, err) => !err ? [
        // Cannot use CommonMetricTags here because it invalidates also current metric
        MetricsTags.Metrics,
        MetricsTags.MetricsWithValues,
        MetricsTags.RevenueDetail,
        MetricsTags.MetricsSummary,
        ReportsTags.Report,
        ReportsTags.Reports,
        ReportsTags.ReportPeriods,
      ] : [],
    }),
    updateMetricsFormat: builder.mutation<void, { metricsIds: string[]; valueFormat: string; precision?: number }>({
      query: (body) => ({
        method: 'POST',
        url: `metrics/bulk_format`,
        body,
      }),
      invalidatesTags: (res, err) => !err ? [
        ...CommonMetricTags,
      ] : [],
    }),
    getMetricsSummary: builder.query<MetricsSummary, void>({
      query: () => ({
        url: 'metrics/summary',
      }),
      providesTags: [MetricsTags.MetricsSummary],
    }),
    putMetricsSummary: builder.mutation<void, string[] | string>({
      query: (body) => ({
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        url: 'metrics/summary',
        body,
      }),
      invalidatesTags: (res, err) => !err ? [
        MetricsTags.MetricsSummary,
      ] : [],
    }),
  }),
});

export const {
  usePostValidateFormulaMutation,
  usePostSaveFormulaMutation,
  useGetAllMetricsQuery,
  useGetAllMetricTypesQuery,
  useAddMetricMutation,
  usePostDuplicateMetricsMutation,
  useGetMetricQuery,
  useUpdateMetricMutation,
  usePostBulkTagsMutation,
  useSyncMetricsMutation,
  useGetMetricChartQuery,
  useUpdateMetricChartMutation,
  usePostRevenueDetailsQuery,
  usePutManualMetricsMutation,
  useGetMetricsWithValuesQuery,
  useDeleteMetricMutation,
  useUpdateMetricsFormatMutation,
  useGetMetricDependantsQuery,
  useGetMetricsSummaryQuery,
  usePutMetricsSummaryMutation,
  useGetOneMetricWithValuesQuery,
} = metricsApi;
