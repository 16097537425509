import {
  format,
  formatISO,
  getYear,
  parseISO,
} from 'date-fns';
import { PeriodType } from 'interfaces/common';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const FrequencyOptions = [
  {
    name: 'Monthly',
    variant: 'monthYearPicker',
    value: PeriodType.MONTHLY,
  },
  {
    name: 'Quarterly',
    variant: 'quarterYearPicker',
    value: PeriodType.QUARTERLY,
  },
  {
    name: 'Annual',
    variant: 'yearPicker',
    value: PeriodType.ANNUAL,
  },
];

/**
 * Format string date "2022-01-31" to "01/31/2022"
 * @param date Javascript date
 * @returns string as 'MM/dd/yyyy'
 */
export const formatDateStringToDisplay = (date: string | undefined | null) => date
  ? format(parseISO(date), 'MM/dd/yyyy')
  : '';

/**
 * Converts number "1" to month name "January"
 * @param monthNum Javascript number
 * @returns string as 'MMMM' format
 */
export const numberToMonthName = (monthNum: number) => months[monthNum - 1];

/**
 * Format JS Date to "MM/dd/yyyy"
 * @param date Javascript Date
 * @returns string as 'MM/dd/yyyy'
 */
export const formatDateToDisplay = (date: Date) => format(date, 'MM/dd/yyyy');

/**
 * Format JS Date to "MM/dd/yyyy HH:mm"
 * @param date Javascript Date
 * @returns string as 'MM/dd/yyyy HH:mm'
 */
export const formatTimeToDisplay = (date: string | undefined | null) => date
  ? format(parseISO(date), 'MM/dd/yyyy HH:mm')
  : '';

/**
 * Format JS date to ISO "2022-01-31"
 * @param date Javascript Date
 * @returns string as '2022-01-31'
 */
export const formatDateToISO = (date: Date) => formatISO(date, {representation: 'date'});

export const isoToDate = (isoDate?: string | null) => isoDate
  ? parseISO(isoDate)
  : undefined;

/**
 * Format JS Date to ISO to JS Date
 * @param date Javascript Date
 * @returns date Javascript ISO Date
 */
export const formatDateToISODate = (date: Date) => isoToDate(formatDateToISO(date))!;

/**
 * Format string date "2022-01-31" to "01/2022"
 * @param date Javascript date
 * @returns string as 'MM/yyyy'
 */
export const formatDateStringToMonthYearDisplay = (date: string | undefined | null) => date
  ? format(parseISO(date), 'MM/yyyy')
  : '';

export const formatDateStringToMMMYearDisplay = (date: string | undefined | null) => date
  ? format(parseISO(date), 'MMM yyyy')
  : '';

/**
 * Base on fiscal year end month number create current month start date "2022-01-01"
 * @param number Javascript number
 * @returns string as '2022-01-31'
 */
export const currentFiscalYearStartMonth = (month: number) => {
  const currentYear = getYear(new Date());
  const fiscalYearStartDate = new Date(currentYear, month);
  return formatDateToISO(fiscalYearStartDate);
};

export const getRidOfCyAndFy = (date: string) => date.replace(/(CY |FY )/, '');
