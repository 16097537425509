import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  LeftPane,
  RightPane,
} from 'components/Layout';
import {
  useNavigate,
  useParams,
} from 'react-router';
import {
  SectionLabel,
  SectionLabelPill,
  SectionMiniLabel,
} from 'components/common/SectionLabel';
import { useGetContractQuery } from 'store/services/contracts';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import {
  DetailBox,
  DetailBoxEntry,
} from 'components/DetailBox';
import { DetailBoxContainer } from 'components/DetailBox/styled';
import { format } from 'currency-formatter';
import { formatDateStringToDisplay } from 'utils/dates';
import { Button } from 'components/common/Button';
import {
  BlockIcon,
  CopyIcon,
  DeleteIcon,
  DownloadIcon,
  LinkIcon,
  RefreshIcon,
  ReloadIcon,
  RotatingRefreshIcon,
} from 'assets/icons';
import { AgGridReact } from '@ag-grid-community/react';
import { useRightPanes } from 'hooks/useRightPanes';
import { GridOptions } from '@ag-grid-community/core';
import { getColumnDefs } from './columnDefinitions';
import { Tooltip } from 'components/common/Tooltip';
import { FactaTable } from 'components/FactaTable';
import {
  highlightNodeById,
  reselectNodeById,
} from 'utils/aggrid';
import { AddContractLine } from '../AddContractLine';
import { Loading } from 'components/Loading';
import { AddTag } from 'pages/Tags/AddTag';
import { EditContractLines } from '../EditContractLines';
import { EditContracts } from '../EditContracts';
import { ContractLine } from 'interfaces/contractLines';
import { DeleteContractLines } from '../DeleteContractLines';
import { DeleteContracts } from '../DeleteContracts';
import { CancelContracts } from '../CancelContracts';
import { useGetContractLinesByContractIdsQuery } from 'store/services/contractLines';
import { copyToClipboard } from 'utils/copyToClipboard';
import { DetailsContainer } from './styled';
import { AddCustomer } from 'pages/Customers/AddCustomer';
import { AddProduct } from 'pages/Products/AddProduct';
import { RenewContractPane } from '../RenewContractPane';
import { LinkContracts } from '../LinkContracts';
import { UnlinkContract } from '../UnlinkContract';
import { renderContractConnections } from 'components/common/AgGridCellRendererContracts';
import { Company } from 'interfaces/company';

interface Props {
  setCustomMarkerData: (customData: {}) => void;
  currentCompany: Company;
}

export const ContractOverview = ({ setCustomMarkerData, currentCompany }: Props) => {
  const navigate = useNavigate();
  const { contractId = '' } = useParams();
  const gridRef = useRef<AgGridReact>(null);
  const columnDefs = getColumnDefs();
  const [selectedRows, setSelectedRows] = useState<ContractLine[]>([]);
  const { rightPanes, setSelectedPane, resetPanes } = useRightPanes({
    add: false,
    edit: true,
    delete: false,
    renewContract: false,
    editContract: false,
    deleteContract: false,
    cancelContract: false,
    linkContract: false,
    unlinkConctract: false,
    addTagFromEditContract: false,
    addTagFromAddContractLine: false,
    addTagFromEditContractLine: false,
    addCustomerFromEditContract: false,
    addProductFromAddContractLine: false,
    addProductFromEditContractLine: false,
  });
  const currencyCode = currentCompany?.currency.code || 'USD';

  const {
    data: contract,
    isLoading: isLoadingContract,
    refetch: refetchContract,
  } = useGetContractQuery(contractId);

  const {
    data: contractLines,
    isLoading: isLoadingContractLines,
    isFetching,
    refetch: refetchContractLines,
  } = useGetContractLinesByContractIdsQuery(contractId);

  const gridOptions: GridOptions = {
    onSelectionChanged: (e) => {
      const selectedRows = e.api.getSelectedRows();
      setSelectedRows(selectedRows);
      if (selectedRows.length) {
        resetPanes();
      }
    },
    enableCharts: true,
    statusBar: {
      statusPanels: [
        { statusPanel: 'agAggregationComponent' },
      ],
    },
  };

  const handleAddContractLine = () => {
    gridRef.current?.api.deselectAll();
    setSelectedPane('add');
  };

  const handleAddTagGoBack = () => {
    if (rightPanes.addTagFromEditContract) {
      setSelectedPane('editContract');
    } else {
      setSelectedPane(rightPanes.addTagFromAddContractLine ? 'add' : 'edit');
    }
  };

  const handleAddProductGoBack = () => {
    setSelectedPane(rightPanes.addProductFromAddContractLine ? 'add' : 'edit');
  };

  const handleTriggerDelete = () => {
    setSelectedPane('delete');
  };

  const handleEditContract = () => {
    gridRef.current?.api.deselectAll();
    setSelectedPane('editContract');
  };

  const handleRenewContract = () => {
    gridRef.current?.api.deselectAll();
    setSelectedPane('renewContract');
  };

  const handleLinkContract = () => {
    gridRef.current?.api.deselectAll();
    setSelectedPane('linkContract');
  };

  const handleCSVDownload = () => gridRef.current?.api.exportDataAsCsv({
    fileName: `insights-contract-${contract?.name.replace(/[^a-z0-9]/gi, '-')
      .toLowerCase() || 'unnamed'}.csv`,
    columnKeys: [
      'name',
      'product.name',
      'product.revenueType',
      'status',
      'amount',
      'mrr',
      'arr',
      'bookingDate',
      'startDate',
      'endDate',
      'cancelDate',
      'tags',
      'crmId',
      'next.name',
      'previous.name',
    ],
    skipRowGroups: true,
    skipColumnGroupHeaders: true,
    processHeaderCallback: ({ column }) => column.getColDef().headerName || '',
  });

  const isAddTagPaneOpen = rightPanes.addTagFromAddContractLine
    || rightPanes.addTagFromEditContractLine
    || rightPanes.addTagFromEditContract;

  const isAddProductPaneOpen = rightPanes.addProductFromAddContractLine
    || rightPanes.addProductFromEditContractLine;

  useEffect(() => {
    setCustomMarkerData({
      selectedContract: contract?.id,
      selectedIds: selectedRows.map((row) => row.id),
    });
  }, [contract?.id, selectedRows, setCustomMarkerData]);

  if (isLoadingContract) {
    return (
      <LeftPane>
        <Loading />
      </LeftPane>
    );
  }

  if (!contract) return (
    <LeftPane>
      Couldn't find contract with provided id: {contractId}
    </LeftPane>
  );

  if (contract.deleted) return (
    <LeftPane>
      This contract ({contract.name}) has been deleted.
    </LeftPane>
  );

  return (
    <>
      <LeftPane>
        <ButtonsContainer>
          <Breadcrumbs>
            <Breadcrumb link="/data-input">Data Sources</Breadcrumb>
            <Breadcrumb link="/data-input/contract-management">Contract Management</Breadcrumb>
            <Breadcrumb link="/data-input/contract-management/contracts">Contracts</Breadcrumb>
            <Breadcrumb>{contract.name}</Breadcrumb>
          </Breadcrumbs>
          <Spacer />
          <Tooltip title="Refresh table data">
            <Button
              variant="icon"
              aria-label="Refresh"
              onClick={() => {
                refetchContractLines();
                refetchContract();
              }}
            >
              {isFetching ? <RotatingRefreshIcon /> : <RefreshIcon />}
            </Button>
          </Tooltip>
          <Tooltip title="Download CSV">
            <Button
              variant="icon"
              aria-label="Download"
              onClick={handleCSVDownload}
            >
              <DownloadIcon />
            </Button>
          </Tooltip>
        </ButtonsContainer>
        <SectionLabel condensed>
          <span>
            {contract.name}
            {contract.status && <SectionLabelPill>{contract.status}</SectionLabelPill>}
          </span>
        </SectionLabel>
        <DetailsContainer>
          <div>
            <SectionMiniLabel>Customer</SectionMiniLabel>
            <p>{contract.customer.name}</p>
          </div>
          <div>
            <SectionMiniLabel>Manage</SectionMiniLabel>
            <ButtonsContainer gap={16}>
              <Tooltip title="Renew Contract">
                <Button
                  variant="icon-bleach"
                  color="primary"
                  size="normal"
                  onClick={handleRenewContract}
                  aria-label="Renew"
                  data-cy="eco-button-renew"
                >
                  <ReloadIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Link Contract">
                <Button
                  variant="icon-bleach"
                  color="primary"
                  size="normal"
                  onClick={handleLinkContract}
                  aria-label="Link"
                  data-cy="eco-button-link"
                >
                  <LinkIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Cancel Contract">
                <Button
                  variant="icon-bleach"
                  color="primary"
                  size="normal"
                  onClick={() => setSelectedPane('cancelContract')}
                  aria-label="Cancel"
                  data-cy="eco-button-cancel"
                >
                  <BlockIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Delete">
                <Button
                  variant="icon-bleach"
                  color="primary"
                  size="normal"
                  onClick={() => setSelectedPane('deleteContract')}
                  aria-label="Delete"
                  data-cy="eco-button-delete"
                >
                  <DeleteIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Copy Contract ID to clipboard">
                <Button
                  variant="icon-bleach"
                  color="primary"
                  size="normal"
                  type="button"
                  onClick={(e) => copyToClipboard(e, contract.id)}
                  data-cy="eco-button-id"
                >
                  <CopyIcon />
                </Button>
              </Tooltip>
            </ButtonsContainer>
          </div>
        </DetailsContainer>
        <DetailBoxContainer>
          <DetailBox
            title="Contract Overview"
            linkTitle="Edit"
            linkAction={handleEditContract}
            flex
          >
            <DetailBoxEntry
              title="Status"
              value={contract.status}
            />
            <DetailBoxEntry
              title="Booking Date"
              value={contract.bookingDate ? formatDateStringToDisplay(contract.bookingDate) : '-'}
            />
            <DetailBoxEntry
              title="Start Date"
              value={contract.startDate ? formatDateStringToDisplay(contract.startDate) : '-'}
            />
            <DetailBoxEntry
              title="End Date"
              value={contract.endDate ? formatDateStringToDisplay(contract.endDate) : '-'}
            />
            <DetailBoxEntry
              title="Cancel Date"
              value={contract.cancelDate ? formatDateStringToDisplay(contract.cancelDate) : '-'}
            />
            {contract.next.length > 0 && (
              <DetailBoxEntry
                title="Next Contract"
                value=""
              >
                {renderContractConnections({ value: contract.next })}
              </DetailBoxEntry>
            )}
            {contract.previous.length > 0 && (
              <DetailBoxEntry
                title={`Previous Contract${contract.previous.length > 1 ? 's' : ''}`}
                value=""
              >
                {renderContractConnections({ value: contract.previous })}
              </DetailBoxEntry>
            )}
          </DetailBox>
          <DetailBox title="Summary Metrics">
            <DetailBoxEntry
              title="TCV"
              value={contract.tcv ? format(contract.tcv, { code: currencyCode }) : '-'}
            />
            <DetailBoxEntry
              title="MRR"
              value={contract.mrr ? format(contract.mrr, { code: currencyCode }) : '-'}
            />
            <DetailBoxEntry
              title="ARR"
              value={contract.arr ? format(contract.arr, { code: currencyCode }) : '-'}
            />
          </DetailBox>
        </DetailBoxContainer>
        <FactaTable
          gridRef={gridRef}
          data={contractLines}
          useNativeFilters
          columnDefs={columnDefs}
          isDisabled={rightPanes.add}
          isLoading={isLoadingContractLines || isFetching}
          onClickAdd={handleAddContractLine}
          selectedRowsLength={selectedRows.length}
          entityName="Contract Line"
          gridOptions={gridOptions}
          showQuickSearch
        />
      </LeftPane>
      <RightPane isOpen={rightPanes.editContract}>
        <EditContracts
          onClose={() => resetPanes()}
          onSuccess={refetchContractLines}
          onSave={(id) => reselectNodeById(id, gridRef)}
          selectedRows={[contract]}
          onAddTag={() => setSelectedPane('addTagFromEditContract')}
          onRenewContract={() => setSelectedPane('renewContract')}
          onDelete={() => setSelectedPane('deleteContract')}
          onCancelContract={() => setSelectedPane('cancelContract')}
          onAddCustomer={() => setSelectedPane('addCustomerFromEditContract')}
          onLinkContract={() => setSelectedPane('linkContract')}
          onUnlinkContract={() => setSelectedPane('unlinkConctract')}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.add}>
        <AddContractLine
          onAddTag={() => setSelectedPane('addTagFromAddContractLine')}
          onClose={resetPanes}
          onSuccess={(id) => highlightNodeById(id, gridRef)}
          contractId={contract.id}
          onAddProduct={() => setSelectedPane('addProductFromAddContractLine')}
        />
      </RightPane>
      <RightPane isOpen={selectedRows.length > 0 && !isAddTagPaneOpen}>
        {rightPanes.edit && (
          <EditContractLines
            selectedRows={selectedRows}
            onClose={() => gridRef.current?.api.deselectAll()}
            onSuccess={(id) => {
              highlightNodeById(id, gridRef);
              refetchContract();
              refetchContractLines();
            }}
            onSave={(id) => reselectNodeById(id, gridRef)}
            onAddTag={() => setSelectedPane('addTagFromEditContractLine')}
            onDelete={handleTriggerDelete}
            onCancelContractLines={() => { }}
            onAddProduct={() => setSelectedPane('addProductFromEditContractLine')}
          />
        )}
        {rightPanes.delete && (
          <DeleteContractLines
            selectedRows={selectedRows}
            onClose={() => {
              resetPanes();
              gridRef.current?.api.deselectAll();
            }}
            onGoBack={() => resetPanes()}
          />
        )}
      </RightPane>
      <RightPane isOpen={rightPanes.cancelContract}>
        <CancelContracts
          selectedRows={[contract]}
          onClose={() => resetPanes()}
          onGoBack={() => {
            resetPanes();
            setSelectedPane('editContract');
          }}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.deleteContract}>
        <DeleteContracts
          selectedRows={[contract]}
          onClose={() => {
            resetPanes();
            gridRef.current?.api.deselectAll();
          }}
          onGoBack={() => setSelectedPane('editContract')}
          onSuccess={() => navigate('/data-input/contract-management/contracts')}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.linkContract}>
        <LinkContracts
          selectedRows={[contract]}
          onClose={() => {
            resetPanes();
            gridRef.current?.api.deselectAll();
          }}
          onGoBack={() => setSelectedPane('editContract')}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.unlinkConctract}>
        <UnlinkContract
          selectedRows={[contract]}
          onClose={() => {
            resetPanes();
            gridRef.current?.api.deselectAll();
          }}
          onGoBack={() => setSelectedPane('editContract')}
        />
      </RightPane>
      <RightPane isOpen={isAddTagPaneOpen}>
        <AddTag
          onClose={() => resetPanes()}
          onGoBack={handleAddTagGoBack}
          isIndirectOpen
        />
      </RightPane>
      <RightPane isOpen={rightPanes.addCustomerFromEditContract}>
        <AddCustomer
          onClose={() => resetPanes()}
          onGoBack={() => setSelectedPane('editContract')}
          isIndirectOpen
        />
      </RightPane>
      <RightPane isOpen={isAddProductPaneOpen}>
        <AddProduct
          onClose={() => resetPanes()}
          onGoBack={handleAddProductGoBack}
          isIndirectOpen
        />
      </RightPane>
      <RightPane isOpen={rightPanes.renewContract}>
        <RenewContractPane
          selectedRows={[contract]}
          onClose={() => {
            resetPanes();
          }}
          onGoBack={() => setSelectedPane('edit')}
        />
      </RightPane>
    </>
  );
};
