import {
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

interface State {
  companyId: string | null;
}

const initialState: State = {
  companyId: null,
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    clear: () => initialState,
    setCompanyId: (state, action: PayloadAction<string>) => {
      state.companyId = action.payload;
    },
  },
});

export const {
  reducer: authReducer,
  actions: authActions,
} = authSlice;
