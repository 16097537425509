import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { Button } from 'components/common/Button';
import {
  DeleteIcon,
  FilterIcon,
  HelpIcon,
  MenuIcon,
  PlusIcon,
  RefreshIcon,
  ReorderLineIcon,
} from 'assets/icons';
import { AgGridContainer } from 'components/common/AgGridContainer';
import {
  ButtonsContainer,
  Divider,
} from 'components/common/ButtonsContainer';
import { LoadingBar } from 'components/common/LoadingBar';
import { StyledQuantityDisplay } from 'components/Tables/styled';
import {
  ColDef,
  FilterChangedEvent,
  GridOptions,
  GridReadyEvent,
  IServerSideGetRowsParams,
  IServerSideSelectionState,
} from '@ag-grid-community/core';
import { commonGridOptions } from 'utils/aggrid';
import { AgGridEmptyState } from 'components/common/AgGridEmptyState';
import { Subscriptions } from 'interfaces/subscriptions';
import { ContractLines } from 'interfaces/contractLines';
import { useDragColumnChange } from 'hooks/useDragColumnChange';
import { SearchResponse } from 'interfaces/api';
import { PER_PAGE } from 'utils/constants';
import { useDebouncedEffect } from 'hooks/useDebouncedEffect';
import {
  useAppDispatch,
  useAppSelector,
} from 'hooks/redux';
import { gridHelperActions } from 'store/slices/gridHelper';
import { gridHelperSelector } from 'store/selectors/gridHelper';
import { FactaTableHelp } from 'components/FactaTableHelp';
import { Tooltip } from 'components/common/Tooltip';
import { AgGridLoadingCellRenderer } from 'components/common/AgGridLoadingCellRenderer';
import { formStateActions } from 'store/slices/formState';

interface Props {
  gridRef: React.RefObject<AgGridReact>;
  columnDefs: ColDef[];
  isDisabled?: boolean;
  onClickAdd?: () => void;
  onClickDelete?: () => void;
  entityName: string;
  gridOptions?: GridOptions;
  customEmptyState?: () => JSX.Element;
  subscriptionsOptions?: Subscriptions;
  contractLinesOptions?: ContractLines;
  useLazyQuery: () => any[];
  quickSearch: string;
}

export const FactaTablePaginated = ({
  gridRef,
  columnDefs,
  isDisabled,
  onClickAdd,
  onClickDelete,
  entityName,
  gridOptions,
  customEmptyState,
  useLazyQuery,
  quickSearch,
}: Props) => {
  const dispatch = useAppDispatch();
  const gridHelper = useAppSelector(gridHelperSelector);
  const [compactMode, setCompactMode] = useState(localStorage.getItem('compactMode') === 'true');
  const [isColumnsChanged, setIsColumnsChanged] = useState(false);
  const [isAGFilteringApplied, setIsAGFilteringApplied] = useState(false);
  const [isHelpVisible, setIsHelpVisible] = useState(false);
  const [search, setSearch] = useState(quickSearch);
  const [isLoadFailed, setIsLoadFailed] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const {
    onDragStarted,
    onDragStopped,
  } = useDragColumnChange(() => setIsColumnsChanged(true));

  const onFilterChanged = useCallback((e: FilterChangedEvent<any, any>) => {
    const keys = Object.keys(e.api.getFilterModel());
    const hasFilters = keys.length !== 0;
    setIsAGFilteringApplied(hasFilters);
  }, []);

  const [getData, { isLoading }] = useLazyQuery();

  const getRows = useCallback((params: IServerSideGetRowsParams) => {
    const page = (params.request.startRow! / PER_PAGE);
    const filterModel = params.api.getFilterModel();

    const filterOptions = Object.fromEntries(
      Object.entries(filterModel)
        .map(([k, v]) => v.filterType === 'set' ? [k, v.values] : [k, v.filter])
    );

    getData({
      pagination: {
        page,
        perPage: PER_PAGE,
      },
      filtering: {
        search: search,
        deleted: false,
        ...filterOptions,
      },
      sortModel: params.request.sortModel,
    })
      .unwrap()
      .then((result: SearchResponse<any>) => {
        params.success({ rowData: result.data, rowCount: result.total });
        dispatch(gridHelperActions.setTotal(result.total));
        setInitialized(true);
      })
      .catch(() => {
        params.fail();
        setIsLoadFailed(true);
      });
  }, [dispatch, getData, search]);

  const createDatasource = useMemo(() => ({
    getRows,
  }), [getRows]);

  const onGridReady = (e: GridReadyEvent) => {
    e.api.setGridOption('serverSideDatasource', createDatasource);
  };

  const combinedGridOptions: GridOptions = {
    ...commonGridOptions,
    ...gridOptions,
    onDragStarted: onDragStarted,
    onDragStopped: onDragStopped,
    onColumnPinned: () => setIsColumnsChanged(true),
    onColumnVisible: () => setIsColumnsChanged(true),
    onGridReady: onGridReady,
    cacheBlockSize: PER_PAGE,
    maxBlocksInCache: 5,
    rowModelType: "serverSide",
    blockLoadDebounceMillis: 500,
    onSelectionChanged: (e) => {
      const selectionState = e.api.getServerSideSelectionState() as IServerSideSelectionState;
      dispatch(gridHelperActions.setSelectionState(selectionState));
      dispatch(formStateActions.clear());
    },
    onFilterChanged,
    sideBar: { toolPanels: ['filters'] },
    onFirstDataRendered: (e) => {
      gridOptions?.onFirstDataRendered && gridOptions.onFirstDataRendered(e);
      dispatch(formStateActions.clear());
    },
  };

  const handleToggleNativeFilter = () => {
    if (gridRef.current?.api.isToolPanelShowing()) {
      gridRef.current?.api.closeToolPanel();
    } else {
      gridRef.current?.api.openToolPanel('filters');
    }
  };

  useEffect(() => {
    setTimeout(() => {
      gridRef.current?.api?.setGridOption('serverSideDatasource', createDatasource);
    }, 0);
  }, [createDatasource, gridRef]);

  const handleClickResetView = useCallback(() => {
    gridRef.current!.api.resetColumnState();
    setIsColumnsChanged(false);
  }, [gridRef]);

  useEffect(() => {
    if (isLoading || !initialized) {
      setTimeout(() => gridRef.current?.api?.showLoadingOverlay(), 0);
    } else if (gridHelper.total === 0) {
      setTimeout(() => gridRef.current?.api?.showNoRowsOverlay(), 0);
    } else {
      setTimeout(() => gridRef.current?.api?.hideOverlay(), 0);
    }
  }, [gridHelper.total, gridRef, initialized, isLoading]);

  useDebouncedEffect(() => {
    setSearch(quickSearch);
    dispatch(gridHelperActions.setQuickSearchFiltering({ search: quickSearch }));
  }, [quickSearch], 300);

  useEffect(() => () => {
    dispatch(gridHelperActions.clear());
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem('compactMode', compactMode ? 'true' : 'false');
  }, [compactMode]);

  const NoRowsOverlay = () => (
    <AgGridEmptyState>
      You don’t have any {entityName.toLowerCase()}s set up yet. Add a {entityName.toLowerCase()} to get started.
      <Button
        variant="contained"
        size="normal"
        onClick={onClickAdd}
      >
        ADD {entityName.toUpperCase()}
      </Button>
    </AgGridEmptyState>
  );

  const handleClearAGFiltering = () => {
    gridRef.current?.api.setFilterModel(null);
    gridRef.current?.api.closeToolPanel();
  };

  const LoadingOverlay = () => (
    <AgGridEmptyState>
      Loading...
    </AgGridEmptyState>
  );

  return (
    <>
      <ButtonsContainer>
        <StyledQuantityDisplay>
          {gridHelper.totalSelected > 0 && `${gridHelper.totalSelected} / `}
          {gridHelper.total || 0} {entityName}{gridHelper.total === 1 ? '' : 's'}
        </StyledQuantityDisplay>
        <Divider />
        <Button
          variant="borderless"
          size="large"
          onClick={handleToggleNativeFilter}
        >
          <FilterIcon />
          Filter
        </Button>
        {onClickDelete && !!gridHelper.totalSelected && (
          <Button
            variant="borderless"
            color="error"
            size="large"
            onClick={onClickDelete}
          >
            <DeleteIcon />
            Delete ({gridHelper.totalSelected})
          </Button>
        )}
        {isColumnsChanged && (
          <>
            <Divider />
            <Button
              variant="borderless"
              color="primary"
              size="large"
              onClick={handleClickResetView}
            >
              Reset View
            </Button>
          </>
        )}
        {isAGFilteringApplied && (
          <>
            <Divider />
            <Button
              variant="borderless"
              color="primary"
              size="large"
              onClick={handleClearAGFiltering}
            >
              Clear All Filters
            </Button>
          </>
        )}
        <Divider />
        <Tooltip title="Advanced table features">
          <Button
            variant="icon"
            color="primary"
            size="large"
            onClick={() => setIsHelpVisible(true)}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
        <Divider />
        <Tooltip title="Toggle compact mode">
          <Button
            variant="icon"
            color="primary"
            onClick={() => setCompactMode(!compactMode)}
          >
            {compactMode ? <ReorderLineIcon /> : <MenuIcon />}
          </Button>
        </Tooltip>
        {isLoadFailed && (
          <Button
            variant="borderless"
            color="error"
            size="large"
            onClick={() => {
              setIsLoadFailed(false);
              gridRef.current?.api.retryServerSideLoads();
            }}
          >
            Failed loading data...
            <RefreshIcon />
            Retry
          </Button>
        )}
        {onClickAdd && (
          <Button
            variant="borderless"
            size="large"
            pushRight
            onClick={onClickAdd}
          >
            <PlusIcon />
            <span>
              Add {entityName}
            </span>
          </Button>
        )}
      </ButtonsContainer>
      <AgGridContainer
        $disabled={isDisabled}
        className="ag-theme-material"
      >
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          gridOptions={combinedGridOptions}
          noRowsOverlayComponent={customEmptyState || NoRowsOverlay}
          loadingOverlayComponent={LoadingOverlay}
          loadingCellRenderer={AgGridLoadingCellRenderer}
          rowHeight={compactMode ? 30 : 48}
          headerHeight={compactMode ? 40 : 48}
        />
        {isLoading && <LoadingBar isWithinTable />}
      </AgGridContainer>
      {isHelpVisible && (
        <FactaTableHelp
          onClose={() => setIsHelpVisible(false)}
          columnDefs={columnDefs}
          gridOptions={combinedGridOptions}
        />
      )}
    </>
  );
};
