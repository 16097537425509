import React from 'react';
import { Tooltip } from 'components/common/Tooltip';
import { Button } from 'components/common/Button';
import styled from 'styled-components';

const Container = styled.div`
  svg {
    width: 168x;
    height: 18px;
  }

  button:hover {
    border-radius: 0;
  }
`;

interface Props {
  isActive?: boolean;
  onClick: () => void;
  tooltip?: string;
  children: JSX.Element | string;
  disabled?: boolean;
  condensed?: boolean;
}

export const ChartButton = ({
  isActive,
  onClick,
  tooltip,
  children,
  disabled,
}: Props) => (
  <Container>
    <Tooltip title={tooltip}>
      <Button
        variant={isActive ? 'contained' : 'outlined'}
        onClick={onClick}
        disabled={disabled}
        active={isActive}
      >
        {children}
      </Button>
    </Tooltip>
  </Container>
);
