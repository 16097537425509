import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { FactaTable } from 'components/FactaTable';
import { LeftPane } from 'components/Layout';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import { SectionLabel } from 'components/common/SectionLabel';
import { getColumnDefs } from './columnDefinitions';
import { useGetAllReportsQuery } from 'store/services/reports';
import { GridOptions } from '@ag-grid-community/core';
import { AddReport } from './AddReport';
import { useRightPanes } from 'hooks/useRightPanes';
import { highlightNodeById } from 'utils/aggrid';
import { Modal } from 'components/Layout/Modal';
import { DeleteReport } from './DeleteReport';
import { DuplicateReport } from './DuplicateReport';
import { Report } from 'interfaces/reports';
import {
  useNavigate,
  useParams,
} from 'react-router';
import { UserRole } from 'interfaces/auth';
import { PreviewReport } from './PreviewReport';
import { Company } from 'interfaces/company';
import { invalidateTags } from 'store/services/api';
import { ReportsTags } from 'store/services/api-tags';
import { useAppDispatch } from 'hooks/redux';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import { Button } from 'components/common/Button';
import { Tooltip } from 'components/common/Tooltip';
import {
  RefreshIcon,
  RotatingRefreshIcon,
} from 'assets/icons';

interface Props {
  currentCompany: Company;
}

export const Reports = ({ currentCompany }: Props) => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<AgGridReact>(null);
  const { reportId = '' } = useParams<{ reportId: string }>();
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [deletingReport, setDeletingReport] = useState('');
  const [previewingReport, setPreviewingReport] = useState(reportId);
  const [duplicatingReport, setDuplicatingReport] = useState<Report | null>(null);
  const { data: reports, isFetching, refetch } = useGetAllReportsQuery();
  const isViewer = currentCompany.role === UserRole.VIEWER;

  const triggerDelete = (id: string) => setDeletingReport(id);
  const triggerDuplicate = (id: string) => {
    const report = reports!.find((r) => r.id === id) || null;
    setDuplicatingReport(report);
  };

  const columnDefs = getColumnDefs({
    isViewer,
    triggerDelete,
    triggerDuplicate,
    navigate,
  });

  const { rightPanes, setSelectedPane, resetPanes } = useRightPanes({
    add: false,
  });

  const gridOptions: GridOptions = {
    onSelectionChanged: (e) => {
      const selectedRows = e.api.getSelectedRows();
      setSelectedRows(selectedRows);
      if (selectedRows.length) {
        resetPanes();
      }
    },
    suppressRowClickSelection: true,
  };

  useEffect(() => {
    setPreviewingReport(reportId);
  }, [reportId]);

  return (
    <>
      <LeftPane>
        <ButtonsContainer>
          <Breadcrumbs>
            <Breadcrumb>
              Reports
            </Breadcrumb>
          </Breadcrumbs>
          <Spacer />
          <Tooltip title="Refresh table data">
            <Button
              variant="icon"
              aria-label="Refresh"
              onClick={refetch}
            >
              {isFetching ? <RotatingRefreshIcon /> : <RefreshIcon />}
            </Button>
          </Tooltip>
        </ButtonsContainer>
        <SectionLabel>
          <span>
            Reports
            {!isViewer && <p>Create Reports using your mapped accounts and uploaded data.</p>}
          </span>
        </SectionLabel>
        <FactaTable
          gridRef={gridRef}
          data={reports}
          useNativeFilters
          columnDefs={columnDefs}
          selectedRowsLength={selectedRows.length}
          entityName={'Report'}
          onClickAdd={isViewer ? undefined : () => setSelectedPane('add')}
          customAddLabel={isViewer ? 'Ask company member to add' : undefined}
          gridOptions={gridOptions}
          showQuickSearch
          isLoading={isFetching}
        />
      </LeftPane>
      <Modal
        isOpen={rightPanes.add}
        onClose={() => resetPanes()}
      >
        <AddReport
          onClose={() => resetPanes()}
          onSuccess={(result) => highlightNodeById(result?.id, gridRef)}
        />
      </Modal>
      <Modal
        isOpen={!!deletingReport}
        onClose={() => setDeletingReport('')}
        maxWidth={700}
      >
        <DeleteReport
          reportId={deletingReport}
          onClose={() => setDeletingReport('')}
        />
      </Modal>
      <Modal
        isOpen={!!duplicatingReport}
        onClose={() => setDuplicatingReport(null)}
        maxWidth={700}
      >
        <DuplicateReport
          report={duplicatingReport!}
          onClose={() => setDuplicatingReport(null)}
          gridRef={gridRef}
        />
      </Modal>
      <Modal
        isOpen={!!previewingReport}
        onClose={() => navigate('/reports')}
        condensed
        fullScreen
      >
        <PreviewReport
          reportId={reportId}
          onClose={() => {
            navigate('/reports');
            dispatch(invalidateTags([ReportsTags.Report]));
          }}
          currentCompany={currentCompany}
        />
      </Modal>
    </>
  );
};
