import * as React from "react";

const SvgComponent = (props: any) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="inherit"
      d="m16.325 18.8-2.2 2.2L12 18.875l2.2-2.2a3.228 3.228 0 0 1-.15-.575c-.033-.2-.05-.4-.05-.6 0-.967.342-1.792 1.025-2.475A3.372 3.372 0 0 1 17.5 12c.3 0 .592.037.875.113.283.075.55.179.8.312L16.8 14.8l1.4 1.4 2.375-2.35c.133.25.238.513.313.787.074.275.112.563.112.863 0 .967-.342 1.792-1.025 2.475A3.372 3.372 0 0 1 17.5 19a3.287 3.287 0 0 1-1.175-.2Zm4.45-8.8H18.7a6.89 6.89 0 0 0-2.475-3.6C15.008 5.467 13.6 5 12 5c-1.95 0-3.604.68-4.963 2.037C5.68 8.396 5 10.05 5 12c0 1.2.27 2.3.813 3.3.541 1 1.27 1.817 2.187 2.45V15h2v6H4v-2h2.35a9.06 9.06 0 0 1-2.45-3.063C3.3 14.73 3 13.418 3 12c0-1.25.237-2.42.712-3.512a9.148 9.148 0 0 1 1.925-2.85 9.148 9.148 0 0 1 2.85-1.925A8.707 8.707 0 0 1 12 3c2.15 0 4.038.663 5.663 1.987 1.625 1.325 2.662 2.996 3.112 5.013Z"
    />
  </svg>
);

export default SvgComponent;
