import styled from "styled-components";
import { theme } from "theme/theme";

export const IconWrapper = styled.div`
  height: 20px;
  cursor: pointer;

  &:hover {
    color: ${theme.colors.primary};
  }

  svg {
    margin: 0 12px;
    width: 18px;
    height: 18px;
  }
`;

export const StyledDatePickerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  .react-datepicker-wrapper {
    width: 100%;
  }
`;