import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FactaTable } from 'components/FactaTable';
import {
  LeftPane,
  RightPane,
} from 'components/Layout';
import { StyledFormContainer } from 'components/common/Forms/styled';
import { Dropdown } from 'components/common/Dropdown';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import { AgGridReact } from '@ag-grid-community/react';
import { useGetManagementDataQuery } from 'store/services/salesComissions';
import { ColDef } from '@ag-grid-community/core';
import {
  currentFiscalYearStartMonth,
  formatDateStringToMMMYearDisplay,
} from 'utils/dates';
import {
  InfoIcon,
  NestedIcon,
} from 'assets/icons';
import {
  NestedField,
  SliderWrapper,
} from 'components/DetailTable/styled';
import { useGetReportPeriodsQuery } from 'store/services/reports';
import { chartDateFormatter } from 'utils/charts';
import { StyledSliderContainer } from 'components/FactaChart/styled';
import Slider from 'rc-slider';
import {
  ManagementCommissionsObject,
  ManagementTableForm,
} from 'interfaces/commissions';
import { useGetSettingsQuery } from 'store/services/settings';
import { multiFilter } from 'utils/filters';
import { Hint } from 'components/common/Hint';

export const ManagementCommissions = () => {
  const gridRef = useRef<AgGridReact>(null);
  const [tempRange, setTempRange] = useState<number[] | null>(null);
  const { data: settings } = useGetSettingsQuery();
  const [range, setRange] = useState(tempRange);
  const { data: availablePeriods } = useGetReportPeriodsQuery();
  const { data, isFetching: isLoading } = useGetManagementDataQuery({
    startDate: range ? availablePeriods?.['monthly']?.[range[0]] : undefined,
    endDate: range ? availablePeriods?.['monthly']?.[range[1]] : undefined,
  }, {
    skip: !range || range[1] < 0 || !availablePeriods || !settings,
  });
  const [colDefs, setColDefs] = useState<ColDef[]>([]);

  const DimensionOptions = useMemo(() => [
    { name: 'Sales Representative' },
    { name: 'Sales Representative Email' },
    { name: 'Customer' },
    { name: 'Commission Event' },
    { name: 'Manager' },
    { name: 'Earned Month' },
    { name: 'Payout Month' },
    { name: 'Invoice To' },
    { name: 'Plan Name' },
    { name: 'None' },
  ], []);

  const gridData: ManagementCommissionsObject[] = useMemo(() => (data?.commissions || []), [data]);

  const initialValues = useMemo(() => ({
    dimension1: DimensionOptions[0],
    dimension2: DimensionOptions.at(-1)!,
    dimension3: DimensionOptions.at(-1)!,
  }), [DimensionOptions]);

  const {
    getValues,
    control,
    watch,
  } = useForm<ManagementTableForm>({
    mode: 'onChange',
    defaultValues: initialValues,
  });

  const {
    dimension1,
    dimension2,
    dimension3,
  } = watch();

  useEffect(() => {
    const getRowGroupIndex = (dimension: string) => {
      switch (dimension) {
        case (dimension1.name):
          return 0;
        case (dimension2.name):
          return 1;
        case (dimension3.name):
          return 2;
        default:
          return 99;
      }
    };

    setTimeout(() => {
      setColDefs([
        {
          field: 'customer',
          hide: true,
          ...multiFilter,
          rowGroup: [dimension1.name, dimension2.name, dimension3.name].includes('Customer'),
          rowGroupIndex: getRowGroupIndex('Customer'),
        },
        {
          field: 'managerName',
          enableRowGroup: true,
          rowGroup: [dimension1.name, dimension2.name, dimension3.name].includes('Manager'),
          rowGroupIndex: getRowGroupIndex('Manager'),
          hide: true,
          ...multiFilter,
        },
        {
          field: 'salesRep',
          headerName: 'Sales Representative',
          enableRowGroup: true,
          rowGroup: [dimension1.name, dimension2.name, dimension3.name].includes('Sales Representative'),
          rowGroupIndex: getRowGroupIndex('Sales Representative'),
          hide: true,
          ...multiFilter,
        },
        {
          field: 'salesRepEmail',
          headerName: 'Sales Representative Email',
          enableRowGroup: true,
          rowGroup: [dimension1.name, dimension2.name, dimension3.name].includes('Sales Representative Email'),
          rowGroupIndex: getRowGroupIndex('Sales Representative Email'),
          hide: true,
          ...multiFilter,
        },
        {
          field: 'commissionEvent',
          enableRowGroup: true,
          rowGroup: [dimension1.name, dimension2.name, dimension3.name].includes('Commission Event'),
          rowGroupIndex: getRowGroupIndex('Commission Event'),
          hide: true,
          ...multiFilter,
        },
        {
          headerName: 'Plan Name',
          field: 'planName',
          enableRowGroup: true,
          rowGroup: [dimension1.name, dimension2.name, dimension3.name].includes('Plan Name'),
          rowGroupIndex: getRowGroupIndex('Plan Name'),
          hide: true,
          ...multiFilter,
        },
        {
          headerName: 'Invoice To',
          field: 'invoiceTo',
          enableRowGroup: true,
          rowGroup: [dimension1.name, dimension2.name, dimension3.name].includes('Invoice To'),
          rowGroupIndex: getRowGroupIndex('Invoice To'),
          hide: true,
          ...multiFilter,
        },
        {
          headerName: 'Earned Month',
          field: 'earnedMonth',
          enableRowGroup: true,
          rowGroup: [dimension1.name, dimension2.name, dimension3.name].includes('Earned Month'),
          rowGroupIndex: getRowGroupIndex('Earned Month'),
          valueFormatter: ({ value }) => formatDateStringToMMMYearDisplay(value) || '-',
          hide: true,
        },
        {
          headerName: 'Payout Month',
          field: 'payoutMonth',
          enableRowGroup: true,
          rowGroup: [dimension1.name, dimension2.name, dimension3.name].includes('Payout Month'),
          rowGroupIndex: getRowGroupIndex('Payout Month'),
          valueFormatter: ({ value }) => formatDateStringToMMMYearDisplay(value) || '-',
          hide: true,
        },
        {
          headerName: 'Commission Note',
          field: 'commissionNote',
          enableRowGroup: true,
          rowGroup: [dimension1.name, dimension2.name, dimension3.name].includes('Commission Note'),
          rowGroupIndex: getRowGroupIndex('Commission Note'),
          hide: true,
        },
        {
          headerName: 'Invoiced',
          field: 'tcv',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Earned Commission',
          field: 'totalEarnedCommission',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Adjustments',
          field: 'payoutAdj',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Calculated Payout',
          field: 'calculatedPayout',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Contract Term',
          field: 'contractTerm',
          sortable: true,
          resizable: true,
          minWidth: 150,
          type: 'rightAligned',
        },
        {
          headerName: 'Net New ARR',
          field: 'netNewARR',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Adj to Net New ARR',
          field: 'adjToNetNewARR',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Adjusted New Net ARR',
          field: 'adjustedNewNetARR',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Net New BCR',
          field: 'netNewBCR',
          sortable: true,
          resizable: true,
          minWidth: 250,
          valueFormatter: ({ value }) => value ? `${(value * 100).toFixed(2)}%` : '',
          type: 'rightAligned',
        },
        {
          headerName: 'Net New Commission',
          field: 'newNetCommission',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Multi-Year ARR',
          field: 'multiYearARR',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
        },
        {
          headerName: 'Adj to Multi-Year ARR',
          field: 'adjToMultiYearARR',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Adjusted Multi-Year ARR',
          field: 'adjustedMultiYearARR',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Multi-Year BCR',
          field: 'multiYearBCR',
          sortable: true,
          resizable: true,
          minWidth: 250,
          valueFormatter: ({ value }) => value ? `${(value * 100).toFixed(2)}%` : '',
          type: 'rightAligned',
        },
        {
          headerName: 'Multi-Year Commission',
          field: 'multiYearCommission',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Non-ARR TCV',
          field: 'nonARRTCV',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Adj to Non-ARR',
          field: 'adjToNonARR',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Adjusted Non-ARR',
          field: 'adjustedNonARR',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Non-ARR BCR',
          field: 'nonARRBCR',
          sortable: true,
          resizable: true,
          minWidth: 250,
          valueFormatter: ({ value }) => value ? `${(value * 100).toFixed(2)}%` : '',
          type: 'rightAligned',
        },
        {
          headerName: 'Non-ARR Commission',
          field: 'nonARRCommission',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Renewal ARR',
          field: 'renewalARR',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Adj to Renewal ARR',
          field: 'adjToRenewalARR',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Adjusted Renewal ARR',
          field: 'adjustedRenewalARR',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Renewal ARR BCR',
          field: 'renewalARRBCR',
          sortable: true,
          resizable: true,
          minWidth: 250,
          valueFormatter: ({ value }) => value ? `${(value * 100).toFixed(2)}%` : '',
          type: 'rightAligned',
        },
        {
          headerName: 'Renewal ARR Commission',
          field: 'renewalARRCommission',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'New Logo Bonus From Rep Email',
          field: 'newLogoBonusFromRepEmail',
          sortable: true,
          resizable: true,
          minWidth: 250,
          valueFormatter: ({ value }) => value ? `${(value * 100).toFixed(2)}%` : '',
          type: 'rightAligned',
        },
        {
          headerName: 'New Logo Commission',
          field: 'newLogoCommission',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Adj to Earned Commissions',
          field: 'adjToEarnedCommissions',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Outstanding Payments',
          field: 'outstandingPayout',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Quota Retirement',
          field: 'quotaRetirement',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Adj to Quota Retirement',
          field: 'adjToQuotaRetirement',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: 'Adj Quota Retirement',
          field: 'adjQuotaRetirement',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: '1st Accelerator Commission',
          field: 'n1stAcceleratorCommission',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: '2nd Accelerator Commission',
          field: 'n2ndAcceleratorCommission',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          headerName: '3rd Accelerator Commission',
          field: 'n3rdAcceleratorCommission',
          sortable: true,
          resizable: true,
          minWidth: 250,
          type: 'currency',
          aggFunc: 'sum',
        },
        {
          field: 'all',
          sortable: false,
          hide: true,
          rowGroup: dimension1.name === 'None',
          valueFormatter: () => 'All',
        },
      ]);
    }, 0);
  }, [data, dimension1.name, dimension2.name, dimension3.name, gridData]);

  const periods = useMemo(() => {
    return availablePeriods?.monthly || [];
  }, [availablePeriods]);

  const sliderMax = useMemo(() => (periods.length || 1) - 1, [periods.length]);

  const sliderMarks = useMemo(() => {
    return data
      ? periods.reduce((a, v, index) => ({ ...a, [index]: chartDateFormatter(v) }), {})
      : {};
  }, [data, periods]);

  useEffect(() => {
    const fiscalMonthIndex = settings?.fiscalYearEnd ? periods.indexOf(currentFiscalYearStartMonth(settings?.fiscalYearEnd)) : 12;
    const lastN = periods.length - fiscalMonthIndex;
    setRange([Math.max(0, periods.length - lastN), periods.length - 1]);
    setTempRange([Math.max(0, periods.length - lastN), periods.length]);
  }, [periods, settings?.fiscalYearEnd]);

  return (
    <>
      <LeftPane>
        <ButtonsContainer>
          <Breadcrumbs>
            <Breadcrumb>Dashboards</Breadcrumb>
            <Breadcrumb>Management Commission Report</Breadcrumb>
          </Breadcrumbs>
        </ButtonsContainer>
        <ButtonsContainer>
          <SectionLabel condensed>
            Management Commission Report
          </SectionLabel>
          <Spacer />
          <SliderWrapper width={((availablePeriods?.monthly.length || 1) * 10) + 40}>
            <StyledSliderContainer>
              <Slider
                range
                min={0}
                max={sliderMax}
                marks={sliderMarks}
                onChange={(values) => setTempRange(values as number[])}
                onChangeComplete={(values) => setRange(values as number[])}
                draggableTrack
                value={tempRange!}
                disabled={isLoading}
              />
            </StyledSliderContainer>
          </SliderWrapper>
        </ButtonsContainer>
        <FactaTable
          useNativeFilters
          gridRef={gridRef}
          data={gridData}
          gridOptions={{
            suppressAggFuncInHeader: true,
            groupDisplayType: 'singleColumn',
            isGroupOpenByDefault: (params) => params.api.getRowGroupColumns().length - 1 > params.level,
            getRowId: (params) => params.data.commissionID,
            rowGroupPanelShow: 'never',
            enableCharts: true,
            statusBar: {
              statusPanels: [
                { statusPanel: 'agAggregationComponent' },
              ],
            },
            onRowGroupOpened: ({ api }) => {
              setTimeout(() => {
                api.autoSizeAllColumns();
              }, 0);
            },
            onFilterModified: ({ api }) => {
              setTimeout(() => {
                api.autoSizeAllColumns();
              }, 0);
            },
            getRowClass: (params) => {
              const levels = params.api.getRowGroupColumns().length;
              if (params.node.group && params.node.level === levels - 3) return 'main-level';
              if (params.node.group && params.node.level === levels - 2) return 'second-level';
              if (params.node.group && params.node.level === levels - 1) return 'third-level';
            },
            suppressRowHoverHighlight: true,
            suppressRowClickSelection: true,
            maintainColumnOrder: false,
          }}
          dynamicGridOptions={{
            autoGroupColumnDef: {
              sort: 'asc',
              resizable: true,
              minWidth: 300,
              pinned: 'left',
              headerName: 'Dimension',
              valueFormatter: (params) => params.data?.customer || '',
              cellRendererParams: (params: any) => {
                return {
                  value: params.node?.field === 'customerStartMonth'
                    ? formatDateStringToMMMYearDisplay(params.value)
                    : params.value,
                };
              },
              headerValueGetter: () => `
                ${dimension1.name}
                ${dimension2.name !== 'None' ? ` / ${dimension2.name}` : ''}
                ${dimension3.name !== 'None' ? ` / ${dimension3.name}` : ''}
              `,
            },
          }}
          columnDefs={colDefs}
          selectedRowsLength={0}
          entityName="commisions"
          suppressAddButton
          suppressQuantityDisplay
          customTitle="Management Commission Report"
          condensed
          isLoading={isLoading}
        />
      </LeftPane>
      <RightPane
        isOpen
        fixed
        collapsible
      >
        <header />
        <main>
          <StyledFormContainer>
            <ButtonsContainer>
              <SectionLabel
                tertiary
                marginBottom={0}
              >
                Options
              </SectionLabel>
              <Spacer />
            </ButtonsContainer>
            <Controller
              name="dimension1"
              control={control}
              render={({ field }) =>
                <Dropdown
                  {...field}
                  labelText="Dimensions"
                  options={DimensionOptions}
                  labelField="name"
                  valueField="name"
                  searchBy="name"
                  values={[getValues('dimension1')]}
                  onChange={(v) => v.length ? field.onChange(v[0]) : undefined}
                  condensed
                  clearable
                  onClearAll={() => field.onChange(DimensionOptions.at(-1))}
                />
              }
            />
            <Controller
              name="dimension2"
              control={control}
              render={({ field }) =>
                <NestedField>
                  <NestedIcon />
                  <Dropdown
                    {...field}
                    options={DimensionOptions}
                    labelField="name"
                    valueField="name"
                    searchBy="name"
                    values={[getValues('dimension2')]}
                    onChange={(v) => v.length ? field.onChange(v[0]) : undefined}
                    condensed
                    clearable
                    onClearAll={() => field.onChange(DimensionOptions.at(-1))}
                  />
                </NestedField>
              }
            />
            <Controller
              name="dimension3"
              control={control}
              render={({ field }) =>
                <NestedField>
                  <svg />
                  <NestedIcon />
                  <Dropdown
                    {...field}
                    options={DimensionOptions}
                    labelField="name"
                    valueField="name"
                    searchBy="name"
                    values={[getValues('dimension3')]}
                    onChange={(v) => v.length ? field.onChange(v[0]) : undefined}
                    condensed
                    clearable
                    onClearAll={() => field.onChange(DimensionOptions.at(-1))}
                  />
                </NestedField>
              }
            />
            <SectionLabel
              tertiary
              marginBottom={0}
            >
              Filters
            </SectionLabel>
            <Hint>
              <InfoIcon />
              Filters have been moved to the table sidebar.
            </Hint>
          </StyledFormContainer>
        </main>
        <footer />
      </RightPane>
    </>
  );
};
