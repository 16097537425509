import React from 'react';
import styled from 'styled-components';

interface WrapperProps {
  pushRight?: boolean;
  maxWidth?: number;
  width?: number;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: ${props => props.pushRight ? 'auto' : 'unset'};
  max-width: ${props => props.maxWidth ? `${props.maxWidth}px` : 'unset'};
  width: ${props => props.width ? `${props.width}px` : 'unset'};
  flex-grow: ${props => props.maxWidth ? 1 : 0};
`;

const FormattingBoxTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 8px;
  min-width: 80px;
`;

const FormattingBoxContent = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  align-items: center;
  min-width: 80px;
  height: 60px;
`;

interface Props extends WrapperProps {
  title: any;
  children: JSX.Element;
}

export const FormattingBox = ({
  title,
  children,
  pushRight,
  maxWidth,
  width,
}: Props) => {
  return (
    <Wrapper
      pushRight={pushRight}
      maxWidth={maxWidth}
      width={width}
    >
      <FormattingBoxTitle>
        {title}
      </FormattingBoxTitle>
      <FormattingBoxContent>
        {children}
      </FormattingBoxContent>
    </Wrapper>
  );
};
