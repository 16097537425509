import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import structuredClone from '@ungap/structured-clone';
import { AgGridReact } from '@ag-grid-community/react';
import { FactaTable } from 'components/FactaTable';
import {
  LeftPane,
  RightPane,
} from 'components/Layout';
import { SectionLabel } from 'components/common/SectionLabel';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'components/common/Breadcrumbs';
import {
  ColDef,
  GridOptions,
  ICellEditorParams,
  ICellRendererParams,
  IRowNode,
} from '@ag-grid-community/core';
import {
  useNavigate,
  useParams,
} from 'react-router';
import { usePopper } from 'react-popper';
import {
  ButtonGroup,
  HeaderButton,
} from './styled';
import {
  ChevronDown,
  DecimalsLeftIcon,
  DecimalsRightIcon,
  DownloadIcon,
  EditIcon,
  FileIcon,
  FormatBoldIcon,
  FormatBorderBottomIcon,
  FormatUnderlineIcon,
  NumberFormatIcon,
  PercentIcon,
  ReorderIcon,
  ReportsIcon,
} from 'assets/icons';
import { ChoosePeriod } from './ChoosePeriod';
import { addMonths } from 'date-fns';
import {
  useAddColumnMutation,
  useAddLineMutation,
  useGetReportQuery,
  useRemoveColumnsMutation,
  useRemoveLinesMutation,
  useUpdateColumnMutation,
  useUpdateColumnsOrderMutation,
  useUpdateSingleLineFormattingMutation,
  useUpdateLineMutation,
  useUpdateLinesOrderMutation,
  useUpdateMultipleLinesFormattingMutation,
} from 'store/services/reports';
import {
  highlightColumnsByIds,
  highlightNodeById,
  scrollToGridBottom,
  scrollToGridRight,
} from 'utils/aggrid';
import { ChangeVersion } from './ChangeVersion';
import { EditColumns } from '../EditColumns';
import { useRightPanes } from 'hooks/useRightPanes';
import { ReportLine } from 'interfaces/reports';
import { useDebouncedEffect } from 'hooks/useDebouncedEffect';
import { useAppDispatch } from 'hooks/redux';
import { commonPopperOptions } from 'utils/popper';
import { formatDateStringToMMMYearDisplay } from 'utils/dates';
import { ReportsTags } from 'store/services/api-tags';
import { invalidateTags } from 'store/services/api';
import { AgGridCellRendererDropdown } from 'components/common/AgGridCellRendererDropdown';
import { AgGridDropdown } from 'components/common/AgGridCellEditors/AgGridDropdown';
import { useGetFactaAccountsQuery } from 'store/services/accounts';
import { useGetAllMetricsQuery } from 'store/services/metrics';
import { ReportConfigurationDropdownItem } from './ReportConfigurationDropdown';
import { Modal } from 'components/Layout/Modal';
import { AddMultipleColumns } from './AddMultipleColumns';
import { Button } from 'components/common/Button';
import { ContextMenu } from 'components/ContextMenu';
import { Tooltip } from 'components/common/Tooltip';
import { EditReportName } from './EditReportName';
import { AddMultipleLines } from './AddMultipleLines';
import { PreviewReport } from '../PreviewReport';
import {
  ButtonsContainer,
  Spacer,
} from 'components/common/ButtonsContainer';
import { format as currencyFormat } from "currency-formatter";
import { toastSuccess } from 'utils/toast';
import { AddMetric } from 'pages/Metrics/AddMetric';
import { EditNotes } from './EditNotes';
import { ValueFormat } from 'interfaces/metrics';
import { Company } from 'interfaces/company';

interface Props {
  currentCompany: Company;
}

export const ReportConfiguration = ({ currentCompany }: Props) => {
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact>(null);
  const dispatch = useAppDispatch();
  const { reportId = '' } = useParams<{ reportId: string }>();
  const [selectedRows, setSelectedRows] = useState<ReportLine[]>([]);
  const [focusedRowId, setFocusedRowId] = useState<string | undefined>(undefined);
  const [columns, setColumns] = useState<ColDef[]>([]);
  const [columnsOrder, setColumnsOrder] = useState<string[]>([]);
  const [gridData, setGridData] = useState<any[] | undefined>(undefined);
  const [editingReportName, setEditingReportName] = useState(false);
  const [editingNode, setEditingNode] = useState<IRowNode | null>(null);
  const [predefinedMetricName, setPredefinedMetricName] = useState('');
  const {
    rightPanes,
    setSelectedPane,
    resetPanes,
  } = useRightPanes({
    editColumns: false,
    addMultipleColumns: false,
    addMultipleLines: false,
    preview: false,
    addMetric: false,
    editNotes: false,
  });

  const currency = currentCompany.currency;

  const [addLine, { isLoading: isAddingReportLine }] = useAddLineMutation();
  const [updateLine] = useUpdateLineMutation();
  const [removeLines] = useRemoveLinesMutation();
  const [updateLinesOrder] = useUpdateLinesOrderMutation();
  const [addColumn, { isLoading: isAddingReportColumn }] = useAddColumnMutation();
  const [updateColumn] = useUpdateColumnMutation();
  const [removeColumns, { isLoading: isRemovingColumns, originalArgs }] = useRemoveColumnsMutation();
  const [updateLineFormatting] = useUpdateSingleLineFormattingMutation();
  const [updateLinesFormatting, { isLoading: isUpdatingFormatting }] = useUpdateMultipleLinesFormattingMutation();
  const [updateColumnsOrder] = useUpdateColumnsOrderMutation();
  const { data: report, isFetching: isLoadingReport } = useGetReportQuery(reportId, { refetchOnMountOrArgChange: true });
  const { data: accounts } = useGetFactaAccountsQuery();
  const { data: metrics } = useGetAllMetricsQuery();

  const columnsBeingRemovedIds = useMemo(() => originalArgs?.columnIds || [], [originalArgs?.columnIds]);

  const accountsOptions = useMemo(() => (accounts || [])
    ?.map((account) => ({
      ...account,
      type: 'Account',
      path: `${account.financialType} / ${account.type}`,
    })), [accounts]);

  const metricsOptions = useMemo(() => (metrics || [])
    .map((metric) => ({
      ...metric,
      type: 'Metric',
      path: `${metric.type.category} / ${metric.type.name}`,
    })), [metrics]);

  const options = [...metricsOptions, ...accountsOptions]
    .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

  // TODO: uncomment when Versioning ready
  // const { data: versions } = useGetReportVersionsQuery();

  const [selectedColumnVersion, setSelectedColumnVersion] = useState<string | null>(null);
  const [changeVersionReferenceElement, setChangeVersionReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [changeVersionPopperElement, setChangeVersionPopperElement] = useState<HTMLDivElement | null>(null);
  const changeVersion = usePopper(changeVersionReferenceElement, changeVersionPopperElement, commonPopperOptions);

  const [selectedColumnPeriod, setSelectedColumnPeriod] = useState<string | null>(null);
  const [changePeriodReferenceElement, setChangePeriodReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [changePeriodPopperElement, setChangePeriodPopperElement] = useState<HTMLDivElement | null>(null);
  const changePeriod = usePopper(changePeriodReferenceElement, changePeriodPopperElement, commonPopperOptions);

  const cmdCtrl = navigator.userAgent.indexOf("Mac") !== -1
    ? '⌘'
    : 'ctrl';

  const onRowDragEnd = () => {
    let linesOrder: any[] = [];
    gridRef.current?.api.forEachNode((node) => {
      linesOrder.push(node.data.id);
    });
    updateLinesOrder({ reportId, linesOrder });
  };

  const onColumnMoved = () => {
    const newColumnsOrder = gridRef.current?.api.getColumns()
      ?.filter((col) => col.getColDef().type === 'data-column')
      ?.sort((col1, col2) => (col1.getLeft() || 0) > (col2.getLeft() || 0) ? 1 : -1)
      ?.map((col) => col.getColId()) || [];

    if (newColumnsOrder.toString() !== columnsOrder.toString()) {
      setColumnsOrder(newColumnsOrder);
    }
  };

  useDebouncedEffect(() => {
    if (columnsOrder.length) {
      updateColumnsOrder({ reportId, columnsOrder });
    }
  }, [columnsOrder], 500);

  const gridOptions: GridOptions = useMemo(() => ({
    suppressClipboardPaste: true,
    rowDragMultiRow: true,
    onCellFocused: ({ rowIndex, api }) => {
      const rowId = rowIndex !== null ? api?.getDisplayedRowAtIndex(rowIndex)?.id : undefined;
      setFocusedRowId(rowId);
    },
    onSelectionChanged: (e) => {
      const selectedRows = e.api.getSelectedRows();
      setSelectedRows(selectedRows);
      if (selectedRows.length) {
        resetPanes();
      }
    },
    defaultColDef: {
      resizable: true,
      initialWidth: 200,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    },
    rowDragManaged: true,
    columnTypes: {
      'data-column': {},
    },
    suppressRowClickSelection: true,
    maintainColumnOrder: true,
    suppressMaintainUnsortedOrder: true,
    onDragStarted: () => false,
    onDragStopped: () => false,
    statusBar: {
      statusPanels: [
        { statusPanel: 'agAggregationComponent' },
      ],
    },
    rowClassRules: {
      'row-bold': ({ data }: any) => data.format?.bold,
      'row-underline': ({ data }: any) => data.format?.underline,
      'row-border': ({ data }: any) => data.format?.border,
    },
  }), [resetPanes]);

  const handleUpdateLine = (rowData: any) => {
    updateLine({
      reportId: report!.id,
      lineId: rowData.id,
      name: rowData.name || null,
      source: rowData.source,
      format: report!.lines.find((line) => line.id === rowData.id)!.format,
    })
      .unwrap()
      .then(() => {
        highlightNodeById(rowData.id, gridRef, true);
      });
  };

  const dynamicGridOptions: GridOptions = {
    onCellEditingStopped: (row) => {
      if (row.oldValue === row.newValue || row.newValue === undefined) return;
      if (row.column.getColId() === 'source') {
        row.api.applyTransactionAsync(
          { update: [{ ...row.data, name: row.data.source.name || '' }] },
          () => handleUpdateLine({
            ...row.data,
            name: row.data.source.name || '',
          }));
      } else {
        handleUpdateLine(row.data);
      }
    },
    onCellKeyDown: ({ event, node: row }: any) => {
      const key = (event as KeyboardEvent)?.key;
      const metaKey = (event as KeyboardEvent)?.metaKey;

      if (key === 'Backspace') {
        handleUpdateLine(row.data);
      }

      if (key === 'b' && metaKey) {
        handleFormatLine('bold');
      }

      if (key === 'u' && metaKey) {
        handleFormatLine('underline');
      }
    },
    onRowDragEnd,
    onColumnMoved,
  };

  const handleChangeVersion = useCallback((target: any, col: string): void => {
    setChangeVersionReferenceElement(target);
    setSelectedColumnVersion(!selectedColumnVersion ? col : null);

    if (changeVersion.update) {
      changeVersion.update();
    }
  }, [changeVersion, selectedColumnVersion]);

  const handleChangePeriod = (target: any, col: string): void => {

    setChangePeriodReferenceElement(target);
    setSelectedColumnPeriod(!selectedColumnPeriod ? col : null);

    if (changePeriod.update) {
      changePeriod.update();
    }
  };

  const handleAddReportLine = useCallback(() => {
    addLine({ reportId })
      .unwrap()
      .then((result) => {
        highlightNodeById(result.slice(-1)[0].id, gridRef);
        scrollToGridBottom();
      });
  }, [addLine, reportId]);

  const handleAddReportColumn = () => {
    addColumn({ reportId })
      .unwrap()
      .then((result) => {
        highlightColumnsByIds([result.id], gridRef);
        scrollToGridRight();
      });
  };

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        field: 'drag',
        headerName: '',
        maxWidth: 52,
        rowDrag: true,
        pinned: 'left',
      },
      {
        field: 'check',
        headerName: '',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        maxWidth: 52,
        pinned: 'left',
      },
      {
        field: 'name',
        headerName: 'Report Line Name',
        editable: true,
        minWidth: 350,
        flex: 1,
        pinned: 'left',
        sortable: false,
      },
      {
        field: 'source',
        headerName: 'Metric',
        editable: true,
        minWidth: 350,
        flex: 1,
        pinned: 'left',
        cellEditor: AgGridDropdown,
        cellEditorPopup: true, // Needs to be true to allow custom cellEditor
        cellEditorPopupPosition: 'over',
        cellEditorParams: (params: ICellEditorParams) => ({
          options,
          width: params.eGridCell.offsetWidth,
          customItemRenderer: ReportConfigurationDropdownItem,
          create: true,
          onCreateNew: (item: any) => {
            setEditingNode(params.node);
            setPredefinedMetricName(item.name);
            setSelectedPane('addMetric');
          },
          optionSpecificProperty: 'type',
        }),
        suppressKeyboardEvent: ({ editing }) => editing,
        cellRenderer: (params: ICellRendererParams) => {
          const { value, data } = params;
          const account = accounts?.find((ac) => ac.id === data.source?.id);
          const link = value && data.source.type === 'Metric'
            ? `/metrics/${data.source.id}`
            : account
              ? `/accounts/account-mapping/${account.financialType === 'Profit & Loss' ? 'profit_and_loss' : 'balance_sheet'}/#${data.source?.id || ''}`
              : '';

          return (
            <AgGridCellRendererDropdown
              gridParams={params}
              link={link}
              tooltip={`Go to ${data.source?.type || ''}`}
            >
              {value || '-'}
            </AgGridCellRendererDropdown>
          );
        },
        valueGetter: ({ data }) => data.source?.name || '',
        sortable: false,
      },
      ...columns,
    ];
  }, [accounts, columns, options, setSelectedPane]);

  const handleApplyVersion = (versionId: string | null, col: string) => {
    updateColumn({
      reportId: report!.id,
      columnId: col,
      version: versionId,
    })
      .unwrap()
      .then(() => {
        highlightColumnsByIds([col], gridRef);
      });
  };

  const handleApplyPeriod = (period: string, col: string) => {
    updateColumn({
      reportId: report!.id,
      columnId: col,
      period: period,
    })
      .unwrap()
      .then(() => {
        highlightColumnsByIds([col], gridRef);
      });
  };

  const handleDelete = () => {
    removeLines({ reportId, lineIds: selectedRows.map((row) => row.id) });
  };

  useEffect(() => {
    if (!report?.columns) return;

    setColumns(report.columns.map((col) => ({
      field: col.id,
      headerName: `${(col.period?.length === 10 && !col.period?.includes('FY'))
        ? formatDateStringToMMMYearDisplay(col.period!)
        : col.period || 'No period'} - ${col.version || 'No version'}`,
      headerComponent: () => (
        <div>
          {/* TODO: uncomment when Versioning ready */}
          {/* <div className="line1">
            <HeaderButton
              onClick={(e) => handleChangeVersion(e.target, col.id)}
              variant="borderless"
              error={col.version === undefined}
            >
              <span>{versions?.find(({ id }) => id === col.version)?.name || 'Select'}</span>
              <ChevronDown />
            </HeaderButton>
          </div> */}
          <div className="line1">
            <HeaderButton
              onClick={(e) => handleChangePeriod(e.target, col.id)}
              variant="borderless"
              error={!col.period}
            >
              <span>
                {(col.period?.length === 10 && !col.period?.includes('FY'))
                  ? formatDateStringToMMMYearDisplay(col.period!)
                  : col.period || 'Select'}
              </span>
              <ChevronDown />
            </HeaderButton>
          </div>
        </div>
      ),
      headerClass: 'custom-header',
      type: 'data-column',
      cellClass: 'ag-right-aligned-cell',
      valueFormatter: ({ value, data }) => {
        if (!data.source || typeof value !== 'number') return '';

        const precision = data.format.precision ?? data.source.precision;
        const valueFormat = data.format.valueFormat || data.source.valueFormat;

        switch (valueFormat) {
          case 'percentage':
            return `${(value * 100).toFixed(precision)}%`;

          case 'currency':
            return `${currencyFormat(value, {
              code: currency.code,
              precision: precision,
            })}`;

          case 'number':
            return `${currencyFormat(value, {
              code: 'USD',
              precision: precision,
              symbol: '',
            })}`;

          case 'multiple':
            return `${currencyFormat(value, {
              code: 'USD',
              precision: precision,
              symbol: '',
            })}x`;

          default:
            return '';
        }
      },
      valueGetter: ({ data }) => data.values?.find((val: any) => val.columnId === col.id)?.value,
    })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report?.columns]);

  const handleFormatSingleLine = (
    action: 'bold' | 'underline' | 'border' | 'decimalsLeft' | 'decimalsRight' | 'valueFormat',
    valueFormat?: ValueFormat
  ) => {
    if (!focusedRowId) return;
    const originalLine = report?.lines.find((line) => line.id === focusedRowId);
    if (!originalLine) return;

    const precision = action === 'decimalsLeft'
      ? Math.max(0, originalLine.format.precision - 1)
      : action === 'decimalsRight'
        ? Math.min(2, originalLine.format.precision + 1)
        : originalLine.format.precision;

    updateLineFormatting({
      reportId: report!.id,
      lineId: focusedRowId,
      name: originalLine.name,
      source: originalLine.source,
      format: {
        bold: action === 'bold' ? !originalLine.format.bold : originalLine.format.bold,
        underline: action === 'underline' ? !originalLine.format.underline : originalLine.format.underline,
        border: action === 'border' ? !originalLine.format.border : originalLine.format.border,
        precision,
        valueFormat: action === 'valueFormat' ? valueFormat! : originalLine.format.valueFormat,
      },
    });
  };

  const handleFormatMultipleLines = (
    action: 'bold' | 'underline' | 'border' | 'decimalsLeft' | 'decimalsRight' | 'valueFormat',
    valueFormat?: ValueFormat
  ) => {
    const reselectedRows = gridRef.current?.api.getSelectedRows();
    if (!reselectedRows?.length) return;

    const maxPrecision = Math.max(...reselectedRows.map(({ format }) => format.precision));

    const precision = action === 'decimalsLeft'
      ? Math.max(0, maxPrecision - 1)
      : action === 'decimalsRight'
        ? Math.min(2, maxPrecision + 1)
        : undefined;

    updateLinesFormatting({
      reportId: report!.id,
      linesIds: selectedRows.map((row) => row.id),
      bold: action === 'bold' ? !reselectedRows[0].format.bold : undefined,
      underline: action === 'underline' ? !reselectedRows[0].format.underline : undefined,
      border: action === 'border' ? !reselectedRows[0].format.border : undefined,
      precision,
      valueFormat: action === 'valueFormat' ? valueFormat : undefined,
    });
  };

  const handleFormatLine = (
    action: 'bold' | 'underline' | 'border' | 'decimalsLeft' | 'decimalsRight' | 'valueFormat',
    valueFormat?: ValueFormat
  ) => {
    if (selectedRows.length > 0) {
      handleFormatMultipleLines(action, valueFormat);
    } else {
      handleFormatSingleLine(action, valueFormat);
    }
  };

  useEffect(() => {
    if (report) {
      setTimeout(() => {
        setGridData(structuredClone(report.lines));
      }, 0);
    }
  }, [report]);

  useEffect(() => () => {
    dispatch(invalidateTags([ReportsTags.Report]));
  }, [dispatch]);

  return (
    <>
      <LeftPane>
        <ButtonsContainer paddingBottom={16}>
          <Breadcrumbs>
            <Breadcrumb link="/reports">
              Reports
            </Breadcrumb>
            <Breadcrumb>
              {`${report?.name || 'loading...'}`}
            </Breadcrumb>
          </Breadcrumbs>
          <Spacer />
          <Tooltip title="Preview Report">
            <Button
              variant="icon"
              aria-label="Refresh"
              onClick={() => setSelectedPane('preview')}
            >
              <ReportsIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Download CSV">
            <Button
              variant="icon"
              aria-label="Download"
              onClick={() => navigate(`/reports/${reportId}/preview#download`)}
            >
              <DownloadIcon />
            </Button>
          </Tooltip>
        </ButtonsContainer>
        <SectionLabel marginBottom={16}>
          <div>
            {editingReportName && (
              <EditReportName
                report={report!}
                onClose={() => setEditingReportName(false)}
              />
            )}
            {!editingReportName && (
              <div>
                {report?.name || 'loading...'}
                {' '}
                <Tooltip
                  inline
                  title="Edit Report Name"
                >
                  <Button
                    onClick={() => setEditingReportName(true)}
                    variant="icon"
                    color="primary"
                    disabled={isLoadingReport}
                  >
                    <EditIcon />
                  </Button>
                </Tooltip>
              </div>)}
          </div>
          <ButtonGroup>
            <Button
              onClick={handleAddReportLine}
              variant="contained"
              minWidth={144}
            >
              ADD REPORT LINE
            </Button>
            <ContextMenu
              items={[
                {
                  name: 'Add Report Line',
                  action: handleAddReportLine,
                },
                {
                  name: 'Add Multiple Report Lines...',
                  action: () => setSelectedPane('addMultipleLines'),
                },
              ]}
              customIcon={<ChevronDown />}
              variant="contained"
            />
            <Button
              onClick={handleAddReportColumn}
              variant="contained"
              minWidth={144}
            >
              ADD COLUMN
            </Button>
            <ContextMenu
              items={[
                {
                  name: 'Add Column',
                  action: handleAddReportColumn,
                },
                {
                  name: 'Add Multiple Columns...',
                  action: () => setSelectedPane('addMultipleColumns'),
                },
              ]}
              customIcon={<ChevronDown />}
              variant="contained"
            />
          </ButtonGroup>
        </SectionLabel>
        {selectedColumnVersion && (
          <ChangeVersion
            popperRef={setChangeVersionPopperElement}
            style={changeVersion.styles.popper}
            attributes={changeVersion.attributes}
            onClose={() => setSelectedColumnVersion(null)}
            onApply={handleApplyVersion}
            col={selectedColumnVersion}
          />
        )}
        {selectedColumnPeriod && (
          <ChoosePeriod
            popperRef={setChangePeriodPopperElement}
            style={changePeriod.styles.popper}
            attributes={changePeriod.attributes}
            popperUpdate={changePeriod.update!}
            onClose={() => setSelectedColumnPeriod(null)}
            onApply={handleApplyPeriod}
            col={selectedColumnPeriod}
            range={{ start: new Date(), end: addMonths(new Date(), 18) }}
          />
        )}
        <FactaTable
          gridRef={gridRef}
          gridOptions={gridOptions}
          dynamicGridOptions={dynamicGridOptions}
          data={gridData}
          columnDefs={columnDefs}
          selectedRowsLength={selectedRows.length}
          onClickDelete={handleDelete}
          isDeleteDisabled={false}
          entityName="report line"
          onClickAdd={handleAddReportLine}
          condensed
          customButtons={[
            {
              title: '',
              variant: 'icon',
              tooltip: `Set format to Number`,
              size: 'large',
              color: 'primary',
              onClick: () => handleFormatLine('valueFormat', 'number'),
              divider: true,
              icon: <NumberFormatIcon />,
            },
            {
              title: '',
              variant: 'icon',
              tooltip: `Set format to Currency`,
              size: 'large',
              color: 'primary',
              onClick: () => handleFormatLine('valueFormat', 'currency'),
              icon: <>{currency.symbol}</>,
            },
            {
              title: '',
              variant: 'icon',
              tooltip: `Set format to Percentage`,
              size: 'large',
              color: 'primary',
              onClick: () => handleFormatLine('valueFormat', 'percentage'),
              icon: <PercentIcon />,
            },
            {
              title: '',
              variant: 'icon',
              tooltip: `Set format to Multiple`,
              size: 'large',
              color: 'primary',
              onClick: () => handleFormatLine('valueFormat', 'multiple'),
              icon: <>x</>,
            },
            {
              title: '',
              variant: 'icon',
              tooltip: `Bold (${cmdCtrl} + B)`,
              size: 'large',
              color: 'primary',
              onClick: () => handleFormatLine('bold'),
              divider: true,
              icon: <FormatBoldIcon />,
            },
            {
              title: '',
              variant: 'icon',
              tooltip: `Underline (${cmdCtrl} + U)`,
              size: 'large',
              color: 'primary',
              onClick: () => handleFormatLine('underline'),
              icon: <FormatUnderlineIcon />,
            },
            {
              title: '',
              variant: 'icon',
              tooltip: 'Border Bottom',
              size: 'large',
              color: 'primary',
              onClick: () => handleFormatLine('border'),
              icon: <FormatBorderBottomIcon />,
            },
            {
              title: '',
              variant: 'icon',
              icon: <DecimalsLeftIcon />,
              tooltip: 'Decrease Decimals',
              size: 'large',
              color: 'primary',
              onClick: () => handleFormatLine('decimalsLeft'),
            },
            {
              title: '',
              variant: 'icon',
              icon: <DecimalsRightIcon />,
              tooltip: 'Increase Decimals',
              size: 'large',
              color: 'primary',
              onClick: () => handleFormatLine('decimalsRight'),
            },
            {
              title: 'Notes',
              icon: <FileIcon />,
              variant: 'borderless',
              size: 'large',
              onClick: () => setSelectedPane('editNotes'),
              disabled: gridData?.length === 0 || isLoadingReport,
              pushRight: true,
            },
            {
              title: 'Edit Columns',
              icon: <ReorderIcon />,
              variant: 'borderless',
              size: 'large',
              onClick: () => setSelectedPane('editColumns'),
              disabled: gridData?.length === 0 || isLoadingReport,
            },
          ]}
          isLoading={isLoadingReport || isAddingReportLine || isAddingReportColumn || isUpdatingFormatting}
          suppressAddButton
          suppressColumnViews
        />
      </LeftPane>
      <RightPane isOpen={rightPanes.editNotes}>
        <EditNotes
          reportId={reportId}
          onClose={() => resetPanes()}
        />
      </RightPane>
      <RightPane isOpen={rightPanes.editColumns}>
        <EditColumns
          reportId={reportId}
          columnsState={report?.columns}
          onClose={() => resetPanes()}
          onSort={(newOrder) => {
            updateColumnsOrder({ reportId, columnsOrder: newOrder })
              .unwrap()
              .then(() => {
                setTimeout(() => {
                  gridRef.current?.api.resetColumnState();
                }, 100);
              });
          }}
          onRemoveColumns={(colIds) => {
            removeColumns({ reportId, columnIds: colIds })
              .unwrap()
              .then(() => {
                toastSuccess(`Column${colIds.length > 1 ? 's' : ''} successfully removed.`);
              });
          }}
          onClickChangePeriod={handleChangePeriod}
          onClickChangeVersion={handleChangeVersion}
          isRemovingColumns={isRemovingColumns}
          columnsBeingRemovedIds={columnsBeingRemovedIds}
        />
      </RightPane>
      <Modal
        isOpen={rightPanes.addMultipleColumns}
        onClose={() => resetPanes()}
        suppressOverflow
      >
        <AddMultipleColumns
          reportId={reportId}
          onClose={() => resetPanes()}
          onSuccess={(result) => {
            setTimeout(() => {
              scrollToGridRight();
              highlightColumnsByIds(result.map(({ id }) => id), gridRef);
            }, 300);
          }}
        />
      </Modal>
      <Modal
        isOpen={rightPanes.addMultipleLines}
        onClose={() => resetPanes()}
        suppressOverflow
        maxWidth={500}
        condensed
      >
        <AddMultipleLines
          reportId={reportId}
          onClose={() => resetPanes()}
          onSuccess={(result) => {
            setTimeout(() => {
              highlightNodeById(result.slice(-1)[0].id, gridRef);
            }, 300);
          }}
        />
      </Modal>
      <Modal
        isOpen={rightPanes.preview}
        onClose={() => resetPanes()}
        condensed
        fullScreen
      >
        <PreviewReport
          reportId={reportId}
          onClose={() => resetPanes()}
          currentCompany={currentCompany}
        />
      </Modal>
      <Modal
        isOpen={rightPanes.addMetric}
        onClose={() => resetPanes()}
      >
        <AddMetric
          onSuccess={(result) => {
            const newData = {
              ...editingNode?.data,
              name: result.name,
              source: {
                id: result.id,
                name: result.name,
                precision: result.precision,
                type: 'Metric',
                valueFormat: result.valueFormat,
              },
            };
            setEditingNode(null);
            setPredefinedMetricName('');
            handleUpdateLine(newData);
          }}
          onClose={() => {
            setEditingNode(null);
            setPredefinedMetricName('');
            resetPanes();
          }}
          predefinedName={predefinedMetricName}
        />
      </Modal>
    </>
  );
};
