import styled, { css } from "styled-components";
import { theme } from "theme/theme";

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;

  button {
    padding: 0;
  }

  > div button {
    border-radius: 0;
    border-right-width: 0;
    min-width: 32px;
    height: 32px;
    
    &:hover {
      box-shadow: none;
    }
  }
  
  > div:first-of-type button {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  
  > div:last-of-type button {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right-width: 1px;
  }
`;

export const StyledSliderContainer = styled.div`
  max-width: 100%;
  padding: 0 20px;
  flex-grow: 1;

  .rc-slider {
    position: relative;
    width: 100%;
    height: 24px;
    margin: 20px 0;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .rc-slider * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .rc-slider-rail {
    position: absolute;
    width: 100%;
    height: 24px;
    background-color: ${theme.colors.borderColorLight};
    border-radius: 6px;
  }

  .rc-slider-track,
  .rc-slider-tracks {
    position: absolute;
    height: 24px;
    background-color: ${theme.colors.primaryBleach};
    border-top: 1px solid ${theme.colors.primary20};
    border-bottom: 1px solid ${theme.colors.primary20};
    border-radius: 6px;
  }

  .rc-slider-track-draggable {
    z-index: 1;
    background-clip: content-box;
    opacity: 0.3;
    background-color: ${theme.colors.primary20};
    border-top: 1px solid ${theme.colors.primary};
    border-bottom: 1px solid ${theme.colors.primary};
  }

  .rc-slider-handle {
    position: absolute;
    z-index: 1;
    width: 10px;
    height: 24px;
    background-color: ${theme.colors.white};
    border: solid 2px ${theme.colors.primary40};
    border-radius: 3px;
    cursor: pointer;
    cursor: -webkit-grab;
    cursor: grab;
    opacity: 0.8;
    touch-action: pan-x;
  }

  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: ${theme.colors.primary};
    box-shadow: 0 0 0 3px ${theme.colors.primary20};
  }

  .rc-slider-handle:focus {
    outline: none;
    box-shadow: none;
  }

  .rc-slider-handle:focus-visible {
    border-color: ${theme.colors.primary};
    box-shadow: 0 0 0 3px ${theme.colors.primary20};
  }

  .rc-slider-handle-click-focused:focus {
    border-color: ${theme.colors.primary20};
    box-shadow: unset;
  }

  .rc-slider-handle:hover {
    border-color: ${theme.colors.primary};
  }

  .rc-slider-handle:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .rc-slider-mark {
    position: absolute;
    left: 0;
    width: 100%;
    font-size: 12px;
  }

  .rc-slider-mark-text {
    font-size: 10px;
    opacity: 0;
    position: absolute;
    display: inline-block;
    color: ${theme.colors.textDisabled};
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    
    &.rc-slider-mark-text-active:not(.rc-slider-mark-text-active ~ *) {
      opacity: 1;
      top: -14px;
    }

    &.rc-slider-mark-text-active:not(:has(~ .rc-slider-mark-text-active)) {
      opacity: 1;
      bottom: -38px;
    }
  }

  .rc-slider-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
  }

  .rc-slider-dot {
    position: absolute;
    width: 2px;
    height: 24px;
    vertical-align: middle;
    background-color: ${theme.colors.white};
    cursor: pointer;
  }

  .rc-slider-dot-active {
    border-color: ${theme.colors.primaryBleach};
  }
`;

interface StyledChartContainerProps {
  fullscreen?: boolean;
  fontSize: number;
}

export const StyledChartContainer = styled.div<StyledChartContainerProps>`
  position: relative;
  width: 100%;
  font-size: 12px;

  ${props => props.fullscreen && css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.white};
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;

    ${CustomTooltipWrapper} {
      height: 1px;
    }
  `}

  .recharts-wrapper {
    width: 100%;
  }

  .recharts-default-legend {
    display: flex;
    overflow-x: auto;
    flex-wrap: nowrap;

    .recharts-legend-item {
      flex-shrink: 0;
      margin: 0 !important;
      padding: 4px 8px;
      cursor: pointer;
      transition: all 300ms ease;
      border: 1px solid ${theme.colors.transparent};
      border-radius: 4px;

      &:first-of-type {
        margin-left: auto !important;
      }

      &:last-of-type {
        margin-right: auto !important;
      }
    }

    .recharts-legend-item:hover {
      opacity: 1;
      border: 1px solid ${theme.colors.primary};
    }
  }

  .recharts-line-dots circle,
  .recharts-bar-rectangle {
    cursor: pointer;
    
    &:hover {
      opacity: 0.8;
    }
  }

  .recharts-text {
    font-size: ${props => props.fontSize}px;
    filter: drop-shadow( 2px 2px 2px white) drop-shadow( -2px -2px 2px white);
  }
`;

export const FormattingContainer = styled.div<{ hidden?: boolean }>`
  display: ${props => props.hidden ? 'none' : 'flex'};
  flex-wrap: wrap;
  gap: 24px;
  border-bottom: 1px solid ${theme.colors.borderColorDark};
  margin-bottom: 24px;
  padding: 16px 0;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  backdrop-filter: blur(15px);
  background-color: rgba(255, 255, 255, 0.8); 
`;

export const CustomTooltipWrapper = styled.div`
  position: relative;
  flex-grow: 1;
`;

export const CustomTooltipPosition = styled.div`
  z-index: 3;
  position: absolute;
  pointer-events: none;
`;

export const PngWrapper = styled.div<{ isGettingPng?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  padding: ${props => props.isGettingPng ? '20' : 0}px;
`;

export const LoadingBarContainer = styled.div`
  margin-top: -24px;
  position: absolute;
  width: 100%;
`;
