import {
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { api } from './services/api';
import { bulkImportReducer } from './slices/bulkImport';
import { contractsReducer } from './slices/contracts';
import { rtkQueryErrorLogger } from './errorHandler';
import { formStateReducer } from './slices/formState';
import { gridHelperReducer } from './slices/gridHelper';
import { accountMappingReducer } from './slices/accountMapping';
import { subscriptionsReducer } from './slices/subscriptions';
import { authReducer } from './slices/auth';

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  bulkImport: bulkImportReducer,
  contracts: contractsReducer,
  formState: formStateReducer,
  gridHelper: gridHelperReducer,
  accountMapping: accountMappingReducer,
  subscriptions: subscriptionsReducer,
  auth: authReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(api.middleware, rtkQueryErrorLogger),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;
