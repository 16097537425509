import styled, { css } from "styled-components";
import { theme } from "theme/theme";
import { StyledFormContainer } from "components/common/Forms/styled";

export const StyledModalWrapper = styled.div<{ opacity: number }>`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(11, 11, 15, 0.6);
  z-index: 3;
  align-items: center;
  justify-content: center;
  opacity: ${props => props.opacity};
  transition: opacity 0.3s ease;
`;

interface StyledModalProps {
  maxWidth?: number;
  minWidth?: number;
  condensed?: boolean
  suppressOverflow?: boolean;
  fullScreen?: boolean;
}

export const StyledModal = styled.div<StyledModalProps>`
  overflow-y:${props => props.suppressOverflow ? 'unset' : 'auto'};
  padding: ${props => props.condensed ? 24 : 40}px;
  background-color: ${theme.colors.white};
  border-radius: 8px;
  box-shadow: ${theme.boxShadowModal};
  max-width: ${props => props.maxWidth || 860}px;
  min-width: ${props => props.minWidth || props.maxWidth || 860}px;
  max-height: 95vh;

  ${StyledFormContainer} {
    padding: 1px !important;
  }

  @media (max-width: 940px) {
    min-width: auto;
    width: 91%;
  }

  ${props => props.fullScreen && css`
    width: calc(100vw - 80px);
    height: calc(100vh - 80px);
    max-width: unset;
  `}
`;

export const StyledModalBoxesContainer = styled.div`
  display: flex;
  gap: 40px;

  & > div {
    flex: 1;
  }
`;